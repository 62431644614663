import React, { useContext, useEffect, useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { UserOutlined, PhoneOutlined, LockOutlined, HeartOutlined } from '@ant-design/icons';
import { Layout, Row, Col, message, Typography, Input, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import axios from 'axios';


const Index = () => {
    const context = useContext(MahabadContext)
    const [getFirstName, setFirstName] = useState(null)
    const [getPhone, setPhone] = useState(null)
    const [getPassword, setPassword] = useState(null)
    const [getInviter, setInviter] = useState(null)
    const [getTerms, setTerms] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const handleRegister = async() => {
        if (context.validator.current.allValid()) {
            let body = {
                "first_name": getFirstName,
                "phone" : getPhone,
                "password" : getPassword
            }
            await axios.post(`${Config.userApi}/register`, body).then(res => {
                messageApi.open({
                    type: 'success',
                    content: 'ثبت نام شما با موفقیت انجام شد در حال انتقال ...',
                });
                setTimeout(()=>{
                    navigate('/login');
                },[2000])
            }).catch((err) => {
                messageApi.open({
                    type: 'error',
                    content: err.response.data.message,
                });
            })
        } else {
            context.validator.current.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            context.forceUpdate(1);
        }
    }
    return (
        <div className='d-flex flex-row align-items-center justify-content-center' style={{ backgroundColor: "#F2F3F7", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center', direction: "rtl" }}>
            {contextHolder}
            <Col xs={22} sm={22} md={22} lg={12} xl={12} className='bg-wh'>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "center" }}>
                    {(context.getSettings !== null) ? (<img style={{ height: "64px", width: "64px" }} src={Config.adminPics + context.getSettings.logo} />) : (<img style={{ height: "64px", width: "64px" }} src={window.origin + "/User/logo.svg"} />)}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>نام و نام خانوادگی <span style={{ color: "red" }}>*</span></p>
                    <Input type="text" onChange={(e) => {
                        setFirstName(e.target.value)
                        context.validator.current.showMessageFor("نام و نام خانوادگی");
                    }} name="نام و نام خانوادگی" size="large" placeholder="نام و نام خانوادگی خود را وارد کنید" prefix={<UserOutlined />} />
                    {context.validator.current.message(
                        "نام و نام خانوادگی",
                        getFirstName,
                        "required|min:3"
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>شماره موبایل <span style={{ color: "red" }}>*</span></p>
                    <Input type="number" onChange={(e) => {
                        setPhone(e.target.value)
                        context.validator.current.showMessageFor("شماره موبایل");
                    }} name="شماره موبایل" size="large" placeholder="شماره موبایل خود را وارد کنید" prefix={<PhoneOutlined />} />
                    {context.validator.current.message(
                        "شماره موبایل",
                        getPhone,
                        "required|min:11|max:11"
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>رمز عبور <span style={{ color: "red" }}>*</span></p>
                    <Input onChange={(e) => {
                        setPassword(e.target.value)
                        context.validator.current.showMessageFor("رمز عبور");
                    }} type="password" name="رمز عبور" size="large" placeholder="رمز عبور خود را وارد کنید" prefix={<LockOutlined />} />
                    {context.validator.current.message(
                        "رمز عبور",
                        getPassword,
                        "required|min:3"
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>کد معرف <span style={{ color: "#6A6A6A", fontSize: "10px" }}>(اختیاری)</span></p>
                    <Input type="number" onChange={(e) => {
                        setInviter(e.target.value)
                        context.validator.current.showMessageFor("کد معرف");
                    }} name="کد معرف" size="large" placeholder="کد معرف خود را وارد کنید" prefix={<HeartOutlined />} />
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>قوانین و مقررات سایت<span style={{ color: "red" }}>*</span></p>
                    <Checkbox onChange={(e) => {
                        setTerms(e.target.checked)
                    }}><span className='text-primary' style={{ fontWeight: "600" }}>با قوانین و مقررات سایت</span> موافقم</Checkbox>

                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <Button onClick={(e) => {
                        handleRegister()
                    }} disabled={getTerms ? (false) : (true)} className='btn-block btn-primary mb-3'>ثبت نام</Button>
                    <p style={{ fontSize: "13px", textAlign: "center", marginBottom: "5px", marginTop: "10px", color: "#b5b5c3" }}>حساب کاربری دارید؟ <Link to="/login" className='text-primary' style={{ fontWeight: 600, fontSize: "14px" }}>وارد شوید</Link></p>
                </Col>
            </Col>
        </div>
    );
};
export default Index;