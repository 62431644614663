import React, { useContext, useEffect } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import Layout from '../Layout/Index';
import Inputo from '../BuyService/input';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { InputGroup } from 'react-bootstrap';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tableo from "./Table"

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Index = () => {
    const context = useContext(MahabadContext)
    const [chipData, setChipData] = React.useState([
        { label: 'Angular' },
        { label: 'jQuery' },
        { label: 'Polymer' },
        { label: 'React' },
        { label: 'Vue.js' },
    ]);
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete.label));
    };

    return (
        <Layout>
            <Container maxWidth="xl" style={{marginBottom:"140px" }}>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/material-ui/getting-started/installation/"
                                >
                                    گزارشات
                                </Link>
                                <Typography color="text.primary">فاکتور های من</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-2 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className='card-service mt-3'>
                                    <Row className='d-flex flex-row align-items-center justify-content-between' gutter={16} style={{gap:"16px"}}>
                                        <Col xs={24} sm={24} md={6} lg={5} xl={5}>
                                            <InputGroup className='inpT'>
                                                <input placeholder='جستجوی فاکتور ...' className='inpotForm' />
                                                <IconButton style={{width:"20%",borderRadius:"100px"}}>
                                                    <img style={{width:"16px",height:"16px"}} src={window.origin + "/User/search.svg"} />
                                                </IconButton>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{textAlign:"left"}}>
                                            <Row className='d-flex flex-row align-items-center justify-content-end' gutter={16} style={{gap:"16px"}}>
                                                <Col>
                                                    <Button className='btnfilters'><FileUploadIcon /> دانلود فایل بصورت Excel</Button>
                                                </Col>
                                                <Col>
                                                    <Button className='btnfilters'><FilterAltIcon/> فیلتر کردن</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr style={{ borderTop: "2px dashed" }} />
                          
                          <Tableo />

                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Layout>
    );
};
export default Index;