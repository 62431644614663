import React, { useContext, useState, useEffect } from 'react';
import MahabadContext from '../../Context/MainContext';
import Config from '../../Config/config.json';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button, Container, IconButton } from '@mui/material';
import Layout from '../Layout/Index';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Tableo from "./Table"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { InputGroup } from 'react-bootstrap';
import axios from 'axios';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Index = () => {
    const context = useContext(MahabadContext)
    const [getTicket, setTicket] = useState(null)
    const navigate = useNavigate();
    const [getText, setText] = useState(null)
    const [chipData, setChipData] = React.useState([
        { label: 'Angular' },
        { label: 'jQuery' },
        { label: 'Polymer' },
        { label: 'React' },
        { label: 'Vue.js' },
    ]);
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete.label));
    };
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <img style={{ width: "32px", height: "32px" }} src={window.origin + "/User/zam.svg"} />
        </button>
    );
    const replayToTicket = async (serviceName) => {
        let body = {
            "text" : getText
        }
     await axios.post(`${Config.userApi}/replayToTicket/${getTicket._id}`,body , {
        headers: {
            "x-auth-token": JSON.parse(context.getCookie("userToken"))
        }
    }).then(res => {
        setTicket(res.data)
        setText("")
        context.setCookie("ticket",JSON.stringify(res.data))
     }).catch((err) => {
        setTicket(null)
     })
 }
    useEffect(() => {
        if(!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === ""){
            navigate('/');
        }else{
            setTicket(JSON.parse(context.getCookie("ticket")))
        }
       
    }, [])
    return (
        <Layout>
            <Container maxWidth="xl" style={{ marginBottom: "120px" }}>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/material-ui/getting-started/installation/"
                                >
                                    مرکز پشتیبانی
                                </Link>
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/material-ui/getting-started/installation/"
                                >
                                    تیکت های من
                                </Link>
                                <Typography color="text.primary">مشاهده ی تیکت</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-start justify-content-between mt-2 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className='card-service mt-3'>
                                    
                                    {/* <hr style={{ borderTop: "2px dashed" }} /> */}
                                    {getTicket !== null ? (
                                       <>
                                        <Tableo getTicket={getTicket} />
                                    <Row className='d-flex flex-row align-items-start justify-content-start'>
                                        <Col>
                                            <img style={{ width: "32px", height: "32px" }} src={window.origin + "/User/client.svg"} />
                                        </Col>
                                        <Col>
                                            <div style={{ backgroundColor: "#F5F5F5", marginRight: "16px", borderRadius: "10px", padding: "16px" }}>
                                                <pre style={{ overflow: "hidden", textAlign: "right", fontSize: "14px", color: "#3F4254" }}>
                                                    {`${getTicket.text}`}
                                                </pre>
                                                <hr style={{ borderTop: "2px dashed" }} />
                                                <p className='mt-3 mb-0' style={{ textAlign: "left" }}>ایجاد : {new Date(getTicket.createdDate).toLocaleTimeString('fa-IR')} | {new Date(getTicket.createdDate).toLocaleDateString('fa-IR')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                       </>

                                    ) : (null)}
                                     {getTicket !== null ? (getTicket.replays.map(item=>
                                        {
                                            if(item.role === "Admin"){
                                                return (<Row className='d-flex flex-row-reverse align-items-start justify-content-start mt-3'>
                                                <Col>
                                                    <img style={{ width: "32px", height: "32px" }} src={window.origin + "/User/admin.svg"} />
                                                </Col>
                                                <Col>
                                                    <div style={{ backgroundColor: "#F5F5F5", marginLeft: "16px", borderRadius: "10px", padding: "16px" }}>
                                                        <pre style={{ overflow: "hidden", textAlign: "right", fontSize: "14px", color: "#3F4254" }}>
                                                            {`${item.text}`}
                                                        </pre>
                                                        <hr style={{ borderTop: "2px dashed" }} />
                                                        <p className='mt-3 mb-0' style={{ textAlign: "left" }}>ایجاد : {new Date(item.createdDate).toLocaleTimeString('fa-IR')} | {new Date(item.createdDate).toLocaleDateString('fa-IR')}</p>
                                                    </div>
                                                </Col>
                                            </Row>)
                                            }else{
                                                return (<Row className='d-flex flex-row align-items-start justify-content-start mt-3'>
                                                <Col>
                                                    <img style={{ width: "32px", height: "32px" }} src={window.origin + "/User/client.svg"} />
                                                </Col>
                                                <Col>
                                                    <div style={{ backgroundColor: "#F5F5F5", marginRight: "16px", borderRadius: "10px", padding: "16px" }}>
                                                        <pre style={{ overflow: "hidden", textAlign: "right", fontSize: "14px", color: "#3F4254" }}>
                                                            {`${item.text}`}
                                                        </pre>
                                                        <hr style={{ borderTop: "2px dashed" }} />
                                                        <p className='mt-3 mb-0' style={{ textAlign: "left" }}>ایجاد : {new Date(item.createdDate).toLocaleTimeString('fa-IR')} | {new Date(item.createdDate).toLocaleDateString('fa-IR')}</p>
                                                    </div>
                                                </Col>
                                            </Row>)
                                            }
                                        }
                                        )) : (null)} 
                                    
                                    <Row className='d-flex flex-row align-items-start justify-content-between mt-5 mb-1' gutter={16}>
                                        {/* <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                                            <Upload
                                                name="avatar"
                                                listType="picture-circle"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                                beforeUpload={beforeUpload}
                                                onChange={handleChange}
                                            >
                                                {imageUrl ? (
                                                    <img
                                                        src={imageUrl}
                                                        alt="avatar"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                ) : (
                                                    uploadButton
                                                )}
                                            </Upload>
                                        </Col> */}
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <InputGroup className='d-flex flex-row align-items-center justify-content-center bho'>
                                                <textarea value={getText} onKeyDown={(e) => {
    // 13 is the key code for Enter key
    if (e.key === "Enter") {
      e.preventDefault(); // این خطا از ارسال فرم جلوگیری می‌کند
      // اینجا می‌توانید متن را ارسال کنید یا هر کار دیگری که نیاز دارید انجام دهید
      replayToTicket()
      setText("")
    }
  }} onChange={(e)=>{
                                                    setText(e.target.value)
                                                }} rows={5} style={{ height: "100px", width: "95%", border: "unset", backgroundColor: "unset" }} />
                                                <p onClick={()=>{
                                                    replayToTicket()
                                                    setText("")
                                                }} style={{ width: "5%", marginBottom: "0px" }}> <img style={{ width: "32px", height: "32px" }} src={window.origin + "/User/send.svg"} /></p>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Container>
        </Layout>
    );
};
export default Index;