import React, { useContext, useEffect } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import Layout from '../Layout/Index';
import Inputo from '../BuyService/input';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { InputGroup } from 'react-bootstrap';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tableo from "./Table"
import List from '@mui/material/List';
import ListItem1 from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import axios from 'axios';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Index = () => {
    const context = useContext(MahabadContext)
    const navigate = useNavigate();
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const handleGetTicketsByStatus = async (status) => {
        await axios.get(`${Config.userApi}/ticketsByStatus/${status}`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("userToken"))
            }
        }).then(res => {
            context.setTickets(res.data)
        }).catch((err) => {
            context.setTickets(null)
        })
    }
useEffect(()=>{
    if(!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === ""){
        navigate('/');
    }else{
        context.handleGetAllTickets()
    }

},[])
    return (
        <Layout>
            <Container maxWidth="xl" style={{ marginBottom: "190px" }}>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/material-ui/getting-started/installation/"
                                >
                                    مرکز پشتیبانی
                                </Link>
                                <Typography color="text.primary">پیام های من</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-start justify-content-between mt-2 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <div className='card-service mt-3'>
                            <Button onClick={() => {
                                context.setTicketModal(true);
                            }} className='btnM'>پیام جدید</Button>
                            <hr style={{ borderTop: "2px dashed" }} />
                            <List>
                                <ListItem1  disablePadding style={{padding:"10px"}}>
                                    <ListItemButton onClick={()=>{
                                       context.handleGetAllTickets()
                                    }} className='d-flex align-items-center'>
                                        <Row className='d-flex flex-row align-items-center justify-content-start' gutter={16}>
                                            <Col><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/allTickets.svg"} /></Col>
                                            <Col>همه ی تیکت ها</Col>
                                        </Row>
                                    </ListItemButton>
                                </ListItem1>
                                <ListItem1 disablePadding style={{padding:"10px"}}>
                                    <ListItemButton onClick={()=>{
                                        handleGetTicketsByStatus("پاسخ داده شده")
                                    }} className='d-flex align-items-center'>
                                        <Row className='d-flex flex-row align-items-center justify-content-start' gutter={16}>
                                            <Col><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/awnsered.svg"} /></Col>
                                            <Col>پاسخ داده شده</Col>
                                        </Row>
                                    </ListItemButton>
                                </ListItem1>
                                <ListItem1 onClick={()=>{
                                        handleGetTicketsByStatus("در انتظار پاسخ")
                                    }} disablePadding style={{padding:"10px"}}>
                                    <ListItemButton className='d-flex align-items-center'>
                                        <Row className='d-flex flex-row align-items-center justify-content-start' gutter={16}>
                                            <Col><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/warning.svg"} /></Col>
                                            <Col>در انتظار پاسخ</Col>
                                        </Row>
                                    </ListItemButton>
                                </ListItem1>
                                {/* <ListItem1 disablePadding style={{padding:"10px"}}>
                                    <ListItemButton className='d-flex align-items-center'>
                                        <Row className='d-flex flex-row align-items-center justify-content-start' gutter={16}>
                                            <Col><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/waiting.svg"} /></Col>
                                            <Col>در انتظار پاسخ</Col>
                                        </Row>
                                    </ListItemButton>
                                </ListItem1> */}
                                {/* <ListItem1 disablePadding style={{padding:"10px"}}>
                                    <ListItemButton className='d-flex align-items-center'>
                                        <Row className='d-flex flex-row align-items-center justify-content-start' gutter={16}>
                                            <Col><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/draft.svg"} /></Col>
                                            <Col>بایگانی شده</Col>
                                        </Row>
                                    </ListItemButton>
                                </ListItem1> */}

                            </List>





                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className='card-service mt-3'>
                                    {/* <Row className='d-flex flex-row align-items-center justify-content-between' gutter={16} style={{ gap: "16px" }}>
                                        <Col xs={24} sm={24} md={6} lg={5} xl={5}>
                                            <InputGroup className='inpT'>
                                                <input placeholder='جستجوی تیکت ...' className='inpotForm' />
                                                <IconButton style={{ width: "20%", borderRadius: "100px" }}>
                                                    <img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/search.svg"} />
                                                </IconButton>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{ textAlign: "left" }}>
                                            <Row className='d-flex flex-row align-items-center justify-content-end' gutter={16}>
                                                <Col>
                                                    <Button className='btnfilters'><FilterAltIcon /> فیلتر کردن</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr style={{ borderTop: "2px dashed" }} /> */}

                                    <Tableo />

                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Layout>
    );
};
export default Index;