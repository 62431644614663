import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MahabadContext from '../../Context/MainContext';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';


export default function SwipeableTemporaryDrawer() {
    const context = useContext(MahabadContext)
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={() => { context.setLeftDrawer(false) }}
            onKeyDown={() => { context.setLeftDrawer(false) }}
        >
            <List style={{ direction: "rtl" }} component={Link} to="/Profile#edite-profile">
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                <img style={{width:"24px",height:"24px"}} src={window.origin + "/User/user3.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>اطلاعات کاربری</p>
                    </ListItemButton>

                </ListItem>
                <ListItem disablePadding component={Link} to="/Profile#financial-information">
                    <ListItemButton>
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                            <img style={{width:"24px",height:"24px"}} src={window.origin + "/User/dollar2.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>اطلاعات مالی</p>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding component={Link} to="/Profile#affiliate">
                    <ListItemButton>
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                            <img style={{width:"24px",height:"24px"}} src={window.origin + "/User/coop.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>همکاری در فروش</p>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding component={Link} to="/Profile#security">
                    <ListItemButton>
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                            <img style={{width:"24px",height:"24px"}} src={window.origin + "/User/lock.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>امنیت</p>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding component={Link} to="/">
                    <ListItemButton onClick={()=>{
                        context.setCookie("userToken",JSON.stringify(null))
                    }}>
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                            <img style={{width:"24px",height:"24px"}} src={window.origin + "/User/power.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>خروج</p>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment key={"left"}>
                {/* <Button onClick={context.toggleDrawer("left", true)}>left</Button> */}
                <SwipeableDrawer
                    anchor={"left"}
                    open={context.leftDrawer}
                    onClose={() => { context.setLeftDrawer(false) }}
                    onOpen={() => { context.setLeftDrawer(true) }}
                >
                    {/* <Row className='d-flex flex-row align-items-center justify-content-start' >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><h3 style={{ textAlign: "right", padding: "16px" }} className='mb-0'>پروفایل من</h3></Col>
                    </Row> */}
                    <Row className='d-flex flex-row-reverse align-items-center justify-content-end w-100' style={{ padding: "16px" }}>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8} style={{ textAlign: 'right' }} className='d-flex flex-row align-items-center justify-content-end'>
                            <div style={{ width: "64px", height: "64px", backgroundColor: 'beige', borderRadius: "10px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                <img style={{ height: "48px", width: "48px" }} src={window.origin + "/User/user1.svg"} />

                            </div>
                        </Col>
                        {context.getCookie("user") ? (<Col xs={12} sm={12} md={16} lg={16} xl={16} style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: "14px", fontWeight: "600" }}>{JSON.parse(context.getCookie("user")).first_name} {JSON.parse(context.getCookie("user")).last_name}</p>
                            <p className='mb-0' style={{ fontSize: "12px", fontWeight: "400" }}>{JSON.parse(context.getCookie("user")).phone}</p>
                        </Col>) : (null)}
                        

                    </Row>
                    <Row className='d-flex flex-row-reverse align-items-center justify-content-end w-100' style={{ paddingRight: "16px", paddingLeft: "16px" }}>
                        <hr className='w-100' style={{ borderTop: "2px dashed" }} />
                    </Row>

                    {list("left")}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}