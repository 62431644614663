import React, { useContext, useEffect , useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import Layout from '../Layout/Index';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const Index = () => {
    const context = useContext(MahabadContext)
    const navigate = useNavigate()
useEffect(()=>{
    if(!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === ""){
        navigate('/');
    }else{
        context.handleGetAllTickets()
        context.handleGetAllSuccessPayments()
    }

},[])
    return (
        <Layout>

            <Container maxWidth="xl">
                <Row className='d-flex flex-row align-items-center justify-content-between' gutter={16}>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Link to="/services?id=google">
                            <div className='cart-bg d-flex flex-column mt-4' style={{ padding: "12px" }}>
                                <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                    <img className='log' src={window.origin + '/User/googleAds.svg'} />
                                    <p style={{ color: "#03A2E9" }}>تبلیغ در گوگل</p>
                                </div>
                                <div className='col-12 mt-4' style={{ textAlign: "left" }}>
                                    <img className='arrow' src={window.origin + '/User/ArrowNext.svg'} />
                                </div>
                            </div>
                        </Link>


                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Link to="/services?id=youtube">
                            <div className='cart-bg d-flex flex-column mt-4' style={{ padding: "12px" }}>
                                <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                    <img className='log' src={window.origin + '/User/youtube.svg'} />
                                    <p style={{ color: "#FF0000" }}>تبلیغ در یوتیوب</p>
                                </div>
                                <div className='col-12 mt-4' style={{ textAlign: "left" }}>
                                    <img className='arrow' src={window.origin + '/User/ArrowNext.svg'} />
                                </div>
                            </div>
                        </Link>

                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Link to="/channels">
                            <div className='cart-bg d-flex flex-column mt-4' style={{ padding: "12px" }}>
                                <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                    <img className='log' src={window.origin + '/User/googleAds.svg'} />
                                    <p style={{ color: "#00dfa6" }}>نقد کردن درآمد یوتیوب</p>
                                </div>
                                <div className='col-12 mt-4' style={{ textAlign: "left" }}>
                                    <img className='arrow' src={window.origin + '/User/ArrowNext.svg'} />
                                </div>
                            </div>
                        </Link>



                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                            <div onClick={()=>{
                                 context.setTicketModal(true);
                            }} className='cart-bg d-flex flex-column mt-4' style={{ padding: "12px" }}>
                                <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                    <img className='log' src={window.origin + '/User/tickets.svg'} />
                                    <p style={{ color: "#1BC5BD" }}>ثبت تیکت جدید</p>
                                </div>
                                <div className='col-12 mt-4' style={{ textAlign: "left" }}>
                                    <img className='arrow' src={window.origin + '/User/ArrowNext.svg'} />
                                </div>
                            </div>

                    </Col>

                </Row>
            </Container>

            <Container maxWidth="xl" style={{ marginBottom: "60px" }}>
                <Row className='d-flex flex-row align-items-start justify-content-between' gutter={16}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <div className='cart-bg1 d-flex flex-column  mt-4'>
                            <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                <img className='dot' src={window.origin + '/User/dot.svg'} />
                                <p className='header'>اکانت های من</p>
                            </div>
                            {(context.getSuccessPayments !== null && context.getSuccessPayments.length > 0) ? (context.getSuccessPayments.map(item=>
        <TableRow
        hover
        className='mt-3'
        role="checkbox"
        tabIndex={-1}
        key={item._id}
        sx={{ cursor: 'pointer' }}
    >
        <TableCell
            align="right"
            component="th"
            scope="row"
            padding="none"
        >
            <Row className="d-flex flex-row align-items-center justify-content-start" gutter={16}>
                <Col>
                    <img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/gg.svg"} />
                </Col>
            </Row>

        </TableCell>
        <TableCell align="right">{new Date(item.createdDate).toLocaleDateString('fa-IR')}</TableCell>
       
        <TableCell align="right"><span className={item.success ? "status-success" :  'status-pending'} >{item.success === true ? ( <span>پرداخت شده</span>) : ( <span >پرداخت نشده</span>)}</span></TableCell>
        <TableCell align="right"><span className={item.status ? "status-success" :  'status-pending'} >{item.status === 'تکمیل شده' ? ( <span>تکمیل شده</span>) : ( <span >در انتظار</span>)}</span></TableCell>
        <TableCell align="center">
            <Row className="d-flex flex-row align-items-center justify-content-end" gutter={16}>
                {item.success === false ? (<Col>
                <Button className='btnDanger'>پرداخت</Button>
                </Col>) : (null)}
                
            </Row>
        </TableCell>
    </TableRow>
                                )) : (<>
                            <div className='col-12 mt-5' style={{ textAlign: "center" }}>
                                <img className='account' src={window.origin + '/User/accountDefault.svg'} />
                            </div>
                            <div className='col-12 mt-4' style={{ textAlign: "center" }}>
                                <p>شما هنوز اکانتی ندارید</p>
                            </div>
                            <div className='col-12 mt-3' style={{ textAlign: "center" }}>
                                <p className="text-ak-steel">اولین  خرید اکانت خودتان را میتوانید <br /> با کلیک روی دکمه زیر انجام دهید</p>
                            </div>
                            <div className='col-12 mt-4' style={{ textAlign: "center" }}>
                                <Button className='primBtn' component={Link} to="/services?id=youtube">خرید اکانت جدید</Button>
                            </div>
                            </>)}
                            
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <div className='cart-bg1 d-flex flex-column  mt-4'>
                            <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                <img className='dot' src={window.origin + '/User/dot.svg'} />
                                <p className='header'>پیام های اخیر من</p>
                            </div>
                            {(context.getTickets !== null) ? (context.getTickets.map(item=>
                              <TableRow
                              hover
                              className='mt-2'
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                              sx={{ cursor: 'pointer' }}
                          >
                              <TableCell
                                  align="right"
                                  component="th"
                                  scope="row"
                                  padding="none"
                              >
                                  <Row className="d-flex flex-row align-items-center justify-content-start" gutter={16}>
                                      <Col>
                                          <img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/message.svg"} />
                                      </Col>
                                      <Col>
                                          <p style={{ marginBottom: "0px", fontSize: "12px", fontWeight: 600 }}>{item.title}</p>

                                      </Col>
                                  </Row>

                              </TableCell>
                              <TableCell align="right">{new Date(item.createdDate).toLocaleDateString('fa-IR')}</TableCell>
                              
                              <TableCell align="right"><span className='status-pending'>{item.status}</span></TableCell>
                              <TableCell align="center">
                                                <Row className="d-flex flex-row align-items-center justify-content-center" gutter={16}>
                                                    <Col>
                                                      <Button onClick={(e)=>{
                                                        context.setCookie('ticket',JSON.stringify(item))
                                                        navigate("/view-ticket")
                                                      }} className='btnView'>مشاهده</Button>
                                                    </Col>
                                                </Row>
                                            </TableCell>
                          </TableRow>
                                )) : (<>
                            <div className='col-12 mt-5' style={{ textAlign: "center" }}>
                            <img className='account' src={window.origin + '/User/messageDefault.svg'} />
                        </div>
                  
                        <div className='col-12 mt-4' style={{ textAlign: "center" }}>
                            <p>شما هنوز پیامی ندارید</p>
                        </div>
                        <div className='col-12 mt-3' style={{ textAlign: "center" }}>
                            <p className="text-ak-steel">برای ارسال پیام میتوانید روی دکمه زیر کلیک کنید
                            </p>
                            <br />
                        </div>
                        <div className='col-12 mt-4' style={{ textAlign: "center" }}>
                            <Button className='primBtn' onClick={()=>{
                             context.setTicketModal(true);
                        }}>ارسال پیام جدید</Button>
                        </div>
                            </>)}
                            
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className='cart-bg1 d-flex flex-column mt-4'>
                            <div className='d-flex flex-row align-items-center justify-content-start col-12'>
                                <img className='dot' src={window.origin + '/User/dot.svg'} />
                                <p className='header'>آمار فعالیت های سایت تبلیغات در گوگل</p>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100 mt-3'>
                                <div className='d-flex flex-row align-items-center justify-content-between itone' style={{ backgroundColor: "#FFF4DE" }}>
                                    <div className='col-4'>
                                        <img className='items' src={window.origin + '/User/touch.svg'} />
                                    </div>
                                    <div className='col-8' style={{ textAlign: "left" }}>
                                        <p >کلیک ها</p>
                                        <p className='mb-0' style={{ fontSize: "16px", fontWeight: 600 }}>+2.36 میلیون</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center justify-content-between itone' style={{ backgroundColor: "#FFE2E5" }}>
                                    <div className='col-4'>
                                        <img className='items' src={window.origin + '/User/eye.svg'} />
                                    </div>
                                    <div className='col-8' style={{ textAlign: "left" }}>
                                        <p >بازدید ها</p>
                                        <p className='mb-0' style={{ fontSize: "16px", fontWeight: 600 }}>+500.61 میلیون</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center justify-content-between itone' style={{ backgroundColor: "#E1F0FF" }}>
                                    <div className='col-4'>
                                        <img className='items' src={window.origin + '/User/b.svg'} />
                                    </div>
                                    <div className='col-8' style={{ textAlign: "left" }}>
                                        <p >هزینه ی تبلیغات انجام شده</p>
                                        <p className='mb-0' style={{ fontSize: "16px", fontWeight: 600 }}>+380.59 میلیون</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};
export default Index;