import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Row, Col } from 'antd';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { visuallyHidden } from '@mui/utils';
import { Button } from '@mui/material';
import MahabadContext from '../../Context/MainContext';

function createData(type, price, date, _id, paymentMethod, status) {
    return {
        type,
        price,
        date,
        _id,
        paymentMethod,
        status,
    };
}



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const rows = [
    createData("سایت تبلیغات در گوگل", "4.700.000", '1402/02/01', 1895, "شبا", "pending"),
    createData("تبلیغات در یوتیوب", "4.700.000", '1402/02/01', 1895, "شبا", "pending"),
   
];
const headCells = [
    {
        id: 'type',
        numeric: false,
        disablePadding: true,
        label: 'نوع سفارش',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'مبلغ سفارش',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'تاریخ ثبت',
    },
    {
        id: '_id',
        numeric: true,
        disablePadding: false,
        label: 'کد پیگیری',
    },
    {
        id: 'paymentMethod',
        numeric: true,
        disablePadding: false,
        label: 'روش پرداخت',
    },
    {
        id: 'success',
        numeric: true,
        disablePadding: false,
        label: 'وضعیت پرداخت',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'وضعیت سفارش',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (

        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>

    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};



export default function EnhancedTable() {
    const context = useContext(MahabadContext)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [locale, setLocale] = React.useState('faIR');

    const theme = useTheme();

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%'}}>
            <ThemeProvider theme={themeWithLocale}>
                <Paper sx={{ width: '100%', mb: 2, boxShadow: "unset" }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {context.getSuccessPayments !== null ? (context.getSuccessPayments.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row._id}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell
                                                align="right"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                <Row className="d-flex flex-row align-items-center justify-content-start" gutter={16}>
                                                    <Col>
                                                        <img style={{ width: "40px", height: "40px" }} src={window.origin + "/User/gg.svg"} />
                                                    </Col>
                                                    <Col>
                                                        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: 600 }}>{row.serviceName}</p>

                                                    </Col>
                                                </Row>

                                            </TableCell>
                                            <TableCell align="right">
                                                <Row className="d-flex flex-row align-items-center justify-content-start" gutter={16}>
                                                    <Col>
                                                        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: 600 }}>{`${row.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} تومان</p>
                                                        {row.success === true ? ( <p style={{ marginBottom: "0px", fontSize: "10px", color: "green", textAlign: "center" }}>پرداخت شده</p>) : ( <p style={{ marginBottom: "0px", fontSize: "10px", color: "red", textAlign: "center" }}>پرداخت نشده</p>)}
                                                       
                                                    </Col>
                                                </Row>

                                            </TableCell>
                                            <TableCell align="right">{new Date(row.createdDate).toLocaleTimeString('fa-IR')} | {new Date(row.createdDate).toLocaleDateString('fa-IR')}</TableCell>
                                            <TableCell align="right">{row._id}</TableCell>
                                            <TableCell align="right"><span className='pay-pending'>درگاه زرین پال</span></TableCell>
                                            <TableCell align="right"><span className={row.success ? "status-success" :  'status-pending'} >{row.success === true ? ( <span>پرداخت شده</span>) : ( <span >پرداخت نشده</span>)}</span></TableCell>
                                            <TableCell align="right"><span className={row.status === "تکمیل شده" ? "status-success" :  'status-pending'} >{row.success === "تکمیل شده" ? ( <span>{row.status}</span>) : ( <span>{row.status}</span>)}</span></TableCell>
                                            <TableCell align="center">
                                                <Row className="d-flex flex-row align-items-center justify-content-end" gutter={16}>
                                                    {row.success === false ? (<Col>
                                                    <Button className='btnDanger'>پرداخت</Button>
                                                    </Col>) : (null)}
                                                    
                                                </Row>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })) : (null)}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </ThemeProvider>

        </Box>
    );
}