import React, { useContext, useEffect, useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col, Input, message, Upload } from 'antd';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Layout from '../Layout/Index';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Uploado from './Uploado';
import { styled } from '@mui/material/styles';
import axios from 'axios';
const { Dragger } = Upload;

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Index = () => {
    const context = useContext(MahabadContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [frag, setFrag] = useState('edite-profile')
    const [getUser, setUser] = useState(null)
    const [title, setTitle] = useState('اطلاعات کاربری')
    const [passwordVisible, setPasswordVisible] = React.useState(true);
    const [value, setValue] = React.useState(0);
    const [getPassword1, setPassword1] = React.useState(null);
    const [getPassword2, setPassword2] = React.useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleUpdateProfile = async () => {
        await axios.put(`${Config.userApi}/profile/edite`, getUser, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("userToken"))
            }
        }).then(res => {
            context.setCookie("user", JSON.stringify(res.data))
            setUser(res.data)
            messageApi.open({
                type: 'success',
                content: "اطلاعات با موفقیت ویرایش یافت",
            });
        }).catch((err) => {
            messageApi.open({
                type: 'error',
                content: err.response.data.message,
            });
        })
    }
    const handleChangePassword = async () => {
        let body = {
            "password1": getPassword1,
            "password2": getPassword2
        }
        await axios.post(`${Config.userApi}/changePassword`, body, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("userToken"))
            }
        }).then(res => {
            messageApi.open({
                type: 'success',
                content: "رمز عبور با موفقیت ویرایش یافت",
            });
        }).catch((err) => {
            messageApi.open({
                type: 'error',
                content: err.response.data.message,
            });
        })
    }
    // می‌توانید از location.hash برای دسترسی به fragment استفاده کنید
    const fragment = location.hash.substring(1);
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const props = {
        name: 'logo',
        multiple: true,
        action: Config.userApi + '/addPhoto',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                if (getUser !== null) {
                    getUser.cart_meli_pic = info.file.response
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const props1 = {
        name: 'logo',
        multiple: true,
        action: Config.userApi + '/addPhoto',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                if (getUser !== null) {
                    getUser.cart_banki_pic = info.file.response
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    useEffect(() => {
        if(!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === ""){
            navigate('/');
        }else{
            setUser(JSON.parse(context.getCookie('user')))
            if (fragment === '') {
                setFrag("edite-profile")
            } else {
                setFrag(fragment)
            }
        }

    }, [fragment])
    return (
        <Layout>
            {contextHolder}
            <Container maxWidth="xl">
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <span
                                >
                                    پروفایل
                                </span>
                                <Typography color="text.primary"> پروفایل من</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                </Row>
                {getUser !== null ? (<Row className='d-flex flex-row align-items-center justify-content-between mt-2 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className='d-flex flex-row align-items-start justify-content-center' gutter={16}>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <div className='card-service mt-3'>
                                    <div className='d-flex flex-row align-items-start justify-content-start bgprof p-3'>
                                        <div><img style={{ width: "96px", height: "96px" }} src={window.origin + "/User/gg.svg"} /></div>
                                        <div className='d-flex flex-column align-items-start justify-content-start pt-1 pb-1' style={{ marginRight: "10px" }}>
                                            <p style={{ fontSize: "16px", fontWeight: 600 }}>{getUser.first_name} {getUser.last_name}</p>
                                            <p className="p1 mb-0">{getUser.phone}</p>
                                        </div>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <Row className="d-flex flex-row align-items-center justify-content-start" gutter={24}>
                                        <Col><Button className={`${frag === "edite-profile" ? "btnHActive" : "btnH"}`} href="#edite-profile">اطلاعات کاربری</Button></Col>
                                        <Col><Button className={`${frag === "financial-information" ? "btnHActive" : "btnH"}`} href="#financial-information">اطلاعات مالی</Button></Col>
                                        <Col><Button className={`${frag === "user-account-level" ? "btnHActive" : "btnH"}`} href="#user-account-level">سطح حساب کاربری</Button></Col>
                                        <Col><Button className={`${frag === "affiliate" ? "btnHActive" : "btnH"}`} href="#affiliate">همکاری در فروش</Button></Col>
                                        <Col><Button className={`${frag === "security" ? "btnHActive" : "btnH"}`} href="#security">امنیت</Button></Col>
                                        {/* <Col><Button className="btnH" href="#edite-profile">تنظیمات</Button></Col> */}
                                    </Row>
                                </div>
                            </Col>
                            {frag === "edite-profile" ? (<Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <div className='card-service mt-3'>
                                    <Row className="d-flex flex-row align-items-start justify-content-center" gutter={24}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>نام</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, first_name: e.target.value })
                                                    }} value={getUser.first_name} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>نام خانوادگی</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, last_name: e.target.value })
                                                    }} value={getUser.last_name} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>ایمیل</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, email: e.target.value })
                                                    }} value={getUser.email} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>نام شرکت</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, company: e.target.value })
                                                    }} value={getUser.company} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>شماره شبا بانکی</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, shaba: e.target.value })
                                                    }} value={getUser.shaba} className='into' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>لینک سایت <span className='text-danger'>*</span></p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, site: e.target.value })
                                                    }} value={getUser.site} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>شماره ثابت</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, phone_2: e.target.value })
                                                    }} value={getUser.phone_2} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>کانال یوتیوب</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, channel: e.target.value })
                                                    }} value={getUser.channel} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>نام صاحب حساب</p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, payment_account_owner: e.target.value })
                                                    }} value={getUser.payment_account_owner} className='into' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                            <Button onClick={() => { handleUpdateProfile() }} className='btnM'>ویرایش اطلاعات کاربری</Button>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>) : (null)}
                            {frag === "financial-information" ? (<Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <div className='card-service mt-3'>
                                    <Row className="d-flex flex-row align-items-start justify-content-center" gutter={24}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>استان <span className='text-danger'>*</span></p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, state: e.target.value })
                                                    }} value={getUser.state} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>شهر <span className='text-danger'>*</span></p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, city: e.target.value })
                                                    }} value={getUser.city} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>آدرس <span className='text-danger'>*</span></p>
                                                    <textarea onChange={(e) => {
                                                        setUser({ ...getUser, address: e.target.value })
                                                    }} value={getUser.address} rows={3} className='into' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>کد ملی <span className='text-danger'>*</span></p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, code_meli: e.target.value })
                                                    }} value={getUser.code_meli} className='into' />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>کدپستی <span className='text-danger'>*</span></p>
                                                    <input onChange={(e) => {
                                                        setUser({ ...getUser, postal_code: e.target.value })
                                                    }} value={getUser.postal_code} className='into' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column mb-3'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>کارت ملی</p>
                                                    <Dragger {...props}>
                                                        <p className="ant-upload-drag-icon">
                                                            <img style={{ width: "64px", height: "64px" }} src={window.origin + "/User/upload.svg"} />
                                                        </p>
                                                        <p className="ant-upload-text">فایل را اینجا بکشید یا کلیک کنید</p>
                                                        <p className="ant-upload-hint">
                                                            فایل های قابل قبولJPG ,PNG
                                                        </p>
                                                    </Dragger>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column mb-3'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>کارت بانکی</p>
                                                    <Dragger {...props1}>
                                                        <p className="ant-upload-drag-icon">
                                                            <img style={{ width: "64px", height: "64px" }} src={window.origin + "/User/upload.svg"} />
                                                        </p>
                                                        <p className="ant-upload-text">فایل را اینجا بکشید یا کلیک کنید</p>
                                                        <p className="ant-upload-hint">
                                                            فایل های قابل قبولJPG ,PNG
                                                        </p>
                                                    </Dragger>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                            <Button onClick={() => { handleUpdateProfile() }} className='btnM mt-3'>ویرایش اطلاعات مالی</Button>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>) : (null)}
                            {frag === "user-account-level" ? (<Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ marginBottom: "150px" }}>
                                <div className='card-service mt-3'>
                                    <p className='mb-0' style={{ textAlign: 'right' }}>userAccountlevel</p>
                                </div>
                            </Col>) : (null)}
                            {frag === "affiliate" ? (<Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <div className='card-service1 mt-3 mb-4 d-flex flex-row align-items-center justify-content-start'>
                                    <Button className='btnInvite'>دریافت لینک</Button>
                                </div>
                                <div className='card-service mt-3'>
                                    <Row className="d-flex flex-column align-items-center justify-content-center" gutter={24}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column align-items-center mb-5' >
                                                    <img style={{ width: "96px", height: "96px", textAlign: "center" }} src={window.origin + "/User/inv.svg"} />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-3' style={{ textAlign: "center", fontSize: "15px", fontWeight: 600, color: "#000", letterSpacing: "1px" }}>دوستانتان را در لذت خرید از سایت تبلیغات در گوگل شریک کنید</p>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-5' style={{ textAlign: "center", fontSize: "13px", fontWeight: 400, color: "#7e829b", letterSpacing: "1px" }}>با دعوت دوستان خود، پس از اولین خرید آن ها از سایت تبلیغات در گوگل پورسانت دریافت کنید</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                            <Button className='btnM'>دریافت لینک همکاری</Button>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>) : (null)}
                            {frag === "security" ? (<Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <div className='card-service mt-3'>
                                    <Row className="d-flex flex-column align-items-center justify-content-center" gutter={24}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>رمز عبور <span className='text-danger'>*</span></p>
                                                    <Input.Password
                                                    onChange={(e)=>{
                                                        setPassword1(e.target.value)
                                                    }}
                                                        placeholder="رمز عبور خود را وارد کنید"
                                                        className='into'
                                                        iconRender={(passwordVisible) => (passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                    />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>تکرار رمز عبور <span className='text-danger'>*</span></p>
                                                    <Input.Password
                                                    onChange={(e)=>{
                                                        setPassword2(e.target.value)
                                                    }}
                                                        placeholder="تکرار رمز عبور را وارد کنید"
                                                        className='into'
                                                        iconRender={(passwordVisible) => (passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                            <Button onClick={()=>{handleChangePassword()}} className='btnM'>بروزرسانی رمز عبور</Button>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>) : (null)}
                        </Row>
                    </Col>

                </Row>) : (null)}

            </Container>
        </Layout>
    );
};
export default Index;