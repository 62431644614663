import React,{useContext} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import MahabadContext from '../../Context/MainContext';
import Config from '../../Config/config.json';
const { Dragger } = Upload;

const App = ({setImage}) => {
  const context = useContext(MahabadContext)
  const props = {
    name: 'logo',
    multiple: true,
    action: Config.userApi + '/addPhoto',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setImage(info.file.name)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  return (<Dragger {...props}>
    <p className="ant-upload-drag-icon">
      <img style={{ width: "64px", height: "64px" }} src={window.origin + "/User/upload.svg"} />
    </p>
    <p className="ant-upload-text">فایل را اینجا بکشید یا کلیک کنید</p>
    <p className="ant-upload-hint">
      فایل های قابل قبولJPG ,PNG
    </p>
  </Dragger>)
}
  ;
export default App;