import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MahabadContext from '../../Context/MainContext';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

export default function SwipeableTemporaryDrawer() {
    const context = useContext(MahabadContext)
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open10, setOpen10] = React.useState(false);
    const [open11, setOpen11] = React.useState(false);
    const [open12, setOpen12] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClick1 = () => {
        setOpen1(!open1);
    };
    const handleClick2 = () => {
        setOpen2(!open2);
    };
    const handleClick3 = () => {
        setOpen3(!open3);
    };
    const handleClick4 = () => {
        setOpen4(!open4);
    };
    const handleClick10 = () => {
        setOpen10(!open10);
    };
    const handleClick11 = () => {
        setOpen11(!open11);
    };
    const handleClick12 = () => {
        setOpen12(!open12);
    };
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
        >
            <List style={{ direction: "rtl" }}>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/dashboard">
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/home.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>داشبورد</p>
                    </ListItemButton>

                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleClick} className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>
                            <ListItemIcon>
                                <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/shop.svg"} />
                                </div>
                            </ListItemIcon>
                            <p className='texzo mb-0'>خرید اکانت</p></div>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton component={Link} to="/services?id=google" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/google.svg"} /> <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>سایت تبلیغات در گوگل</p>
                        </ListItemButton>
                        <ListItemButton component={Link} to="/services?id=youtube" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/y.svg"} /> <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات در یوتیوب</p>
                        </ListItemButton>
                        <ListItemButton component={Link} to="/services?id=appstore" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/astore.svg"} /> <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات نصب اپلیکیشن</p>
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/renew-account">
                        <ListItemIcon>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/renew.svg"} />
                            </div>
                        </ListItemIcon>
                        <p className='texzo mb-0'>تمدید اکانت</p>
                    </ListItemButton>

                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleClick1() }} className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>

                            <ListItemIcon>
                                <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/y.svg"} />
                                </div>
                            </ListItemIcon>
                            <p className='texzo mb-0'>خدمات یوتیوب</p>
                        </div>
                        {open1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                </ListItem>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton component={Link} to="/addChannel" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>افزودن کانال جدید</p>
                        </ListItemButton>
                        <ListItemButton component={Link} to="/channels" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>نقد کردن درآمد</p>
                        </ListItemButton>
                        <ListItemButton component={Link} to="/services?id=youtube" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات در یوتیوب</p>
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleClick4() }} className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>

                            <ListItemIcon>
                                <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/tool.svg"} />
                                </div>
                            </ListItemIcon>
                            <p className='texzo mb-0'>خرید ابزار</p>
                        </div>
                        {open4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                </ListItem>
                <Collapse in={open4} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleClick10() }} className='d-flex flex-row align-items-center justify-content-between'>
                                <div className='d-flex flex-row align-items-center'>
                                    <p className='texzo mb-0'>ابزارهای سئو</p>
                                </div>
                                {open10 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>

                        </ListItem>
                        <Collapse in={open10} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton component={Link} to="/services?id=semrush" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار SEMrush
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=ahrefs" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار Ahrefs
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=moz-pro" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار MOZ Pro
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=similarweb" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار Similarweb
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=woorank" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار WooRank
                                    </p>
                                </ListItemButton>

                            </List>
                        </Collapse>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleClick11() }} className='d-flex flex-row align-items-center justify-content-between'>
                                <div className='d-flex flex-row align-items-center'>
                                    <p className='texzo mb-0'>ابزارهای یوتیوب</p>
                                </div>
                                {open11 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>

                        </ListItem>
                        <Collapse in={open11} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton component={Link} to="/services?id=TubeBuddy" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار TubeBuddy
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=vid-iq" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                        خرید ابزار VidIQ
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=tube-ranker" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار TubeRanker
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=canva" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار Canva
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=free-pik" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار Freepik
                                    </p>
                                </ListItemButton>
                                <ListItemButton component={Link} to="/services?id=social-blade" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار Social Blade
                                    </p>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleClick12() }} className='d-flex flex-row align-items-center justify-content-between'>
                                <div className='d-flex flex-row align-items-center'>
                                    <p className='texzo mb-0'>ابزارهای جلوگیری از کلیک فیک</p>
                                </div>
                                {open12 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>

                        </ListItem>
                        <Collapse in={open12} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            <ListItemButton component={Link} to="/services?id=Clickcease" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار Click Cease
                                    </p>
                                </ListItemButton>
                            <ListItemButton component={Link} to="/services?id=Clickguard" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار Click GUARD
                                    </p>
                                </ListItemButton>
                            <ListItemButton component={Link} to="/services?id=PPCShield" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>
                                    خرید ابزار PPC Shield
                                    </p>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleClick2() }} className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>

                            <ListItemIcon>
                                <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/checklist.svg"} />
                                </div>
                            </ListItemIcon>
                            <p className='texzo mb-0'>گزارشات</p>
                        </div>
                        {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                </ListItem>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton component={Link} to="/my-orders" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>سفارشات من</p>
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleClick3() }} className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>

                            <ListItemIcon>
                                <div style={{ width: "40px", height: "40px", borderRadius: "5px", backgroundColor: "#f3f6f9" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <img style={{ width: "24px", height: "24px" }} src={window.origin + "/User/support1.svg"} />
                                </div>
                            </ListItemIcon>
                            <p className='texzo mb-0'>مرکز پشتیبانی</p>
                        </div>
                        {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                </ListItem>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton onClick={(e) => {
                            context.setSubMenuActive("ارسال پیام جدید")
                            context.setTicketModal(true);
                        }} sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>ارسال پیام جدید</p>
                        </ListItemButton>
                        <ListItemButton component={Link} to="/my-tickets" sx={{ pl: 4 }} className='p-2 pt-3 pb-3'>
                            <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>پیام های من</p>
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment key={"left"}>
                {/* <Button onClick={context.toggleDrawer("left", true)}>left</Button> */}
                <SwipeableDrawer
                    anchor={"left"}
                    open={context.mainDrawer}
                    onClose={() => { context.setMainDrawer(false) }}
                    onOpen={() => { context.setMainDrawer(true) }}
                >
                    {/* <Row className='d-flex flex-row align-items-center justify-content-start' >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><h3 style={{ textAlign: "right", padding: "16px" }} className='mb-0'>پروفایل من</h3></Col>
                    </Row> */}
                    <Row className='d-flex flex-row-reverse align-items-center justify-content-end w-100' style={{ padding: "16px" }}>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8} style={{ textAlign: 'right' }} className='d-flex flex-row align-items-center justify-content-end'>
                            <div style={{ width: "64px", height: "64px", backgroundColor: 'beige', borderRadius: "10px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                <img style={{ height: "48px", width: "48px" }} src={window.origin + "/User/user1.svg"} />

                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={16} lg={16} xl={16} style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: "14px", fontWeight: "600" }}>اسماعیل صالح زاده</p>
                            <p className='mb-0' style={{ fontSize: "12px", fontWeight: "400" }}>09146315182</p>
                        </Col>

                    </Row>
                    <Row className='d-flex flex-row-reverse align-items-center justify-content-end w-100' style={{ paddingRight: "16px", paddingLeft: "16px" }}>
                        <hr className='w-100' style={{ borderTop: "2px dashed" }} />
                    </Row>

                    {list("left")}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}