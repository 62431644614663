import React, { useContext , useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MahabadContext from '../../Context/MainContext';
import Config from '../../Config/config.json';
import { Col, Row, message } from 'antd';
import { InputGroup } from 'react-bootstrap';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs() {
    const context = useContext(MahabadContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [getTitle, setTitle] = useState()
    const [getText, setText] = useState()
    const [getName, setName] = useState()
    const [getPriority, setPriority] = useState()
    const [getDepartman, setDepartman] = useState()
    const handleClickOpen = () => {
        context.setTicketModal(true);
    };
    const handleClose = () => {
        context.setTicketModal(false);
    };
    const handleSendTicket = async () => {
        if (context.validator.current.allValid()) {
            let body = {
                "title": getTitle,
                "text": getText,
                "name": getName,
                "priority": getPriority,
                "departman": getDepartman
            }
            await axios.post(`${Config.userApi}/sendTicket`, body,{
                headers : {
                    "x-auth-token" : JSON.parse(context.getCookie('userToken'))
                }
            }).then(res => {
                messageApi.open({
                    type: 'success',
                    content: "تیکت با موفقیت ارسال شد",
                });
                handleClose()
            }).catch((err) => {
                console.log(err)
            })
        } else {
            context.validator.current.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            context.forceUpdate(1);
        }
        
    }
    return (
        <React.Fragment style={{ direction: 'rtl' }}>
            {contextHolder}
            <BootstrapDialog
                style={{ direction: 'rtl' }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={context.getTicketModal}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        left: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <p style={{ fontSize: "20px", color: "black", marginTop: "25px", textAlign: "center", fontWeight: 600 }}>ارسال پیام جدید</p>
                    <Typography gutterBottom style={{ textAlign: "center", color: "#9B9FAC", fontSize: "13px" }}>
                        اگر سوال و پشتیبانی نیاز دارین فقط کافیه فرم زیر را تکمیل کنید
                    </Typography>
                    <Row gutter={16} className='mt-4'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                            <p className='mb-2' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>نام و نام خانوادگی <span className='text-danger'>*</span></p>
                            <input className='into' onChange={(e)=>{
                                setName(e.target.value)
                                context.validator.current.showMessageFor("نام و نام خانوادگی");
                            }} />
                            {context.validator.current.message(
                        "نام و نام خانوادگی",
                        getName,
                        "required|min:3"
                    )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                            <p className='mb-2' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>موضوع پیام <span className='text-danger'>*</span></p>
                            <input className='into' onChange={(e)=>{
                                setTitle(e.target.value)
                                context.validator.current.showMessageFor("موضوع پیام");
                            }} />
                            {context.validator.current.message(
                        "موضوع پیام",
                       getTitle,
                        "required|min:3"
                    )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                            <p className='mb-2' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>اولویت <span className='text-danger'>*</span></p>
                            <select className='into' onChange={(e)=>{
                                setPriority(e.target.value)
                                context.validator.current.showMessageFor("اولویت پیام");
                            }}>
                                <option value="کم">کم</option>
                                <option value="متوسط">متوسط</option>
                                <option value="زیاد">زیاد</option>
                            </select>
                            {context.validator.current.message(
                        "اولویت پیام",
                        getPriority,
                        "required"
                    )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                            <p className='mb-2' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>مربوط به بخش <span className='text-danger'>*</span></p>
                            <select className='into' onChange={(e)=>{
                                setDepartman(e.target.value)
                                context.validator.current.showMessageFor("دپارتمان");
                            }}>
                                <option value="فروش">فروش</option>
                                <option value="پشتیبانی">پشتیبانی</option>
                            </select>
                            {context.validator.current.message(
                        "دپارتمان",
                        getDepartman,
                        "required"
                    )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                            <p className='mb-2' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>توضیحات <span className='text-danger'>*</span></p>
                            <textarea rows={3} className='into' onChange={(e)=>{
                                setText(e.target.value)
                                context.validator.current.showMessageFor("توضیحات");
                            }} />
                            {context.validator.current.message(
                        "توضیحات",
                        getText,
                        "required"
                    )}
                        </Col>
                        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                            <Row gutter={16}>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <InputGroup>
                                        <InputGroup className='inpT'>
                                            <input placeholder='فایلی انتخاب نشده است' className='inpotForm1' />
                                            <IconButton style={{ width: "40%", borderRadius: "100px", fontSize: "14px" }}>
                                                انتخاب کردن
                                            </IconButton>
                                        </InputGroup>
                                    </InputGroup>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Button className='btnSendo'>فایل جدید</Button>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>

                </DialogContent>
                <DialogActions className='d-flex flex-row justify-content-start mr-2'>
                    <Button className='btnM0' autoFocus onClick={()=>{
                        handleSendTicket()
                    }}>
                        ارسال پیام جدید
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}