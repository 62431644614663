import React, { useContext, useEffect, useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Layout, Row, Col, theme, Typography, Input, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { format, toJalaali } from 'date-fns';
import Avatar from '@mui/material/Avatar';
import { faIR } from 'date-fns/locale';
import { Button, Container, Grid, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Modal from './Modal';
import Menu from './menu';
import Youtube from './youtube';
import Fake from './fake';
import DrawerLeft from './DrawerLeft';
import DrawerMain from './DrawerMain';
import moment from 'jalali-moment';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const Index = ({ children }) => {
    const context = useContext(MahabadContext)
    const [getUser, setUser] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === "") {
            navigate('/');
        } else {
            setUser(JSON.parse(context.getCookie('user')))
            context.handleGetAllSettings()
        }

    }, [])
    return (

        <>
            <Modal />
            <DrawerLeft />
            <DrawerMain />
            <Grid container justifyContent={"space-between"} direction={"row"} style={{ direction: "rtl", backgroundColor: "#F2F3F7", height: "100dvh" }}>
<Grid  item xs={12} sm={12} md={12} lg={12} xl={12}>
    
<Paper elevation={0} sx={{ display: { xs: "none", sm: "none", md: 'none', xl: 'block', lg: "block" } }} className='w-100' style={{ position: "sticky", top: "0px", zIndex: 99}}>
                    <Row className='menu'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='menuBox d-flex flex-column align-items-center justify-content-center'>
                            <Container maxWidth="xl">
                                <Row justify={"space-between"} alignItems={"center"} style={{ width: "100%" }}>
                                    <Col xs={16} sm={2} md={22} lg={22} xl={19}>
                                        <Row justify={"start"} alignItems={"center"}>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='bg-white d-flex flex-row align-items-center justify-content-center' style={{ height: "70px" }}>
                                                <Link to="/dashboard">
                                                    <img style={{ width: "32px", height: "32px" }} src={window.origin + '/User/logo_tablighat_dar_google.webp'} />
                                                </Link>
                                            </Col>
                                            <Col xs={21} sm={21} md={21} lg={21} xl={21} className='d-flex flex-row align-items-center justify-content-center' style={{ height: "70px" }}>
                                                <ul className='header-tabs nav align-self-end font-size-lg ml-10 ml-md-11 ml-xl-15'>
                                                    <li className='nav-item'>
                                                        <Link to="/dashboard" className={`nav-link ${context.getActive === 'داشبورد' ? "active" : ""}`} onClick={(e) => {
                                                            context.setActive(e.target.innerText)
                                                            context.setSubMenuActive("داشبورد")
                                                        }}>داشبورد</Link>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <a className={`nav-link ${context.getActive === 'خرید اکانت' ? "active" : ""}`} onClick={(e) => {
                                                            context.setActive(e.target.innerText)
                                                        }}>خرید اکانت</a>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <Link to="/renew-account" className={`nav-link ${context.getActive === 'تمدید اکانت' ? "active" : ""}`} onClick={(e) => {
                                                            context.setActive(e.target.innerText)
                                                            context.setSubMenuActive("تمدید اکانت")
                                                        }}>تمدید اکانت</Link>
                                                    </li>

                                                    {/* <li className='nav-item'>
                                                            <a className={`nav-link ${context.getActive === 'خدمات یوتیوب' ? "active" : ""}`} onClick={(e) => { context.setActive(e.target.innerText) }}>خدمات یوتیوب</a>
                                                        </li> */}
                                                    <li className='nav-item'>
                                                        <a className={`nav-link ${context.getActive === 'خرید ابزار' ? "active" : ""}`} onClick={(e) => { context.setActive(e.target.innerText) }}>خرید ابزار</a>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <a className={`nav-link ${context.getActive === 'گزارشات' ? "active" : ""}`} onClick={(e) => { context.setActive(e.target.innerText) }}>گزارشات</a>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <a className={`nav-link ${context.getActive === 'مرکز پشتیبانی' ? "active" : ""}`} onClick={(e) => { context.setActive(e.target.innerText) }}>مرکز پشتیبانی</a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {getUser !== null ? (<Col xs={8} sm={8} md={2} lg={2} xl={5} className='endItems'>
                                        <Row align="middle" justify="end" className='w-100'>
                                            {/* <Col xs={4} sm={4} md={4} lg={12} xl={3}>
                                                    <Badge color="error" badgeContent={5}
                                                        size="large" >
                                                        <NotificationsIcon style={{ color: "white" }} />
                                                    </Badge>
                                                </Col> */}
                                            <Col xs={8} sm={8} md={8} lg={12} xl={9}>
                                                <p className='text-white text-center mb-0' >{getUser.first_name} {getUser.last_name}</p>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={12} xl={3} style={{ textAlign: "left" }}>
                                                <div onClick={() => {
                                                    context.setLeftDrawer(true)
                                                }}>

                                                    <Avatar variant="square" {...stringAvatar(`${getUser.first_name} ${getUser.last_name}`)} />
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>) : (null)}

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                    <Row className='d-flex flex-row align-items-center justify-content-start bg-white w-100'>
                        {(context.getActive === "داشبورد" || context.getActive === "تمدید اکانت") ? (<Container maxWidth="xl">
                            <Row className='d-flex flex-row align-items-center justify-content-between pt-3 pb-3'>
                                {context.getCookie("user") ? (<Col className='ml-3'>
                                    <img src={window.origin + "/User/user.svg"} /> <span className='text-secondary'>{JSON.parse(context.getCookie("user")).first_name} {JSON.parse(context.getCookie("user")).last_name} عزیز به سایت تبلیغات در گوگل خوش آمدید</span>
                                </Col>) : (null)}

                                <Col className='ml-3'>
                                    <div className='datebox'>
                                        <span className='text-secondary'>امروز: {moment(new Date(Date.now())).locale('fa').format('dddd DD MMMM yyyy')} | {moment(new Date(Date.now())).locale('en').format('DD yyyy,MMMM')}</span>    <img src={window.origin + "/User/date.svg"} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>) : (null)}
                        {(context.getActive === "خرید اکانت") ? (
                            <Container maxWidth="xl">
                                <Row className='d-flex flex-row align-items-center justify-content-start pt-3 pb-3' style={{ marginLeft: "0px", marginRight: "0px" }} gutter={24}>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'تبلیغات در گوگل' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/services?id=google">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/google.svg"} /> <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات در گوگل</p></Button>
                                        </Link>

                                    </Col>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'تبلیغات در یوتیوب' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/services?id=youtube">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/y.svg"} /> <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات در یوتیوب</p></Button>
                                        </Link>
                                    </Col>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'تبلیغات نصب اپلیکیشن' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/services?id=appstore">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/astore.svg"} /> <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات نصب اپلیکیشن</p></Button>
                                        </Link>
                                    </Col>
                                    {/* <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'تبلیغات در لینکدین' ? "selected-bg" : "un-selected-bg"}`}>
                            <Button onClick={(e)=>{
                                context.setSubMenuActive(e.target.innerText)
                            }}><img style={{width:"16px",height:"16px",marginLeft:"10px"}} src={window.origin + "/User/in.svg"} /> <p className='mb-0' style={{fontSize:"13px",color:"#000000"}}>تبلیغات در لینکدین</p></Button>
                            </Col>
                            <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'تبلیغات در اکس' ? "selected-bg" : "un-selected-bg"}`}>
                            <Button onClick={(e)=>{
                                context.setSubMenuActive(e.target.innerText)
                            }}><img style={{width:"16px",height:"16px",marginLeft:"10px"}} src={window.origin + "/User/xo.svg"} /> <p className='mb-0' style={{fontSize:"13px",color:"#000000"}}>تبلیغات در اکس</p></Button>
                            </Col>
                            <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'سایر تبلیغات' ? "selected-bg" : "un-selected-bg"}`}>
                            <Button onClick={(e)=>{
                                context.setSubMenuActive(e.target.innerText)
                            }}><img style={{width:"16px",height:"16px",marginLeft:"10px"}} src={window.origin + "/User/od.svg"} /> <p className='mb-0' style={{fontSize:"13px",color:"#000000"}}>سایر تبلیغات</p></Button>
                            </Col> */}

                                </Row>
                            </Container>
                        ) : (null)}
                        {/* {(context.getActive === "خدمات یوتیوب") ? (
                                <Container maxWidth="xl">
                                    <Row className='d-flex flex-row align-items-center justify-content-start pt-3 pb-3' style={{ marginLeft: "0px", marginRight: "0px" }} gutter={24}>
                                        <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'افزودن کانال جدید' ? "selected-bg" : "un-selected-bg"}`}>
                                            <Link to="/addChannel">
                                                <Button onClick={(e) => {
                                                    context.setSubMenuActive(e.target.innerText)
                                                }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>افزودن کانال جدید</p></Button>
                                            </Link>

                                        </Col>
                                        <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'نقد کردن درآمد' ? "selected-bg" : "un-selected-bg"}`}>
                                            <Link to="/channels">
                                                <Button onClick={(e) => {
                                                    context.setSubMenuActive(e.target.innerText)
                                                }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>نقد کردن درآمد</p></Button>
                                            </Link>

                                        </Col>
                                        <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'تبلیغات در یوتیوب' ? "selected-bg" : "un-selected-bg"}`}>
                                            <Link to="/services?id=youtube">
                                                <Button onClick={(e) => {
                                                    context.setSubMenuActive(e.target.innerText)
                                                }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>تبلیغات در یوتیوب</p></Button>
                                            </Link>

                                        </Col>
                                    </Row>
                                </Container>
                            ) : (null)} */}
                        {(context.getActive === "خرید ابزار") ? (
                            <Container maxWidth="xl">
                                <Row className='d-flex flex-row align-items-center justify-content-start pt-3 pb-3' style={{ marginLeft: "0px", marginRight: "0px" }} gutter={24}>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'ابزار کیورد پلنر' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/services?id=keyword-planer">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>ابزار کیورد پلنر</p></Button>
                                        </Link>

                                    </Col>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'ابزارهای سئو' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Menu />
                                    </Col>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'ابزارهای یوتیوب' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Youtube />
                                    </Col>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'ابزار جلوگیری از کلیک فیک' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Fake />
                                    </Col>

                                </Row>
                            </Container>
                        ) : (null)}
                        {(context.getActive === "گزارشات") ? (
                            <Container maxWidth="xl">
                                <Row className='d-flex flex-row align-items-center justify-content-start pt-3 pb-3' style={{ marginLeft: "0px", marginRight: "0px" }} gutter={24}>
                                    {/* <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'سفارشات من' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/my-orders">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>سفارشات من</p></Button>
                                        </Link>

                                    </Col>
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'فاکتور های من' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/my-factors">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>فاکتور های من</p></Button>
                                        </Link>

                                    </Col> */}
                                    <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'سفارشات من' ? "selected-bg" : "un-selected-bg"}`}>
                                        <Link to="/my-payments">
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>سفارشات من</p></Button>
                                        </Link>

                                    </Col>

                                </Row>
                            </Container>
                        ) : (null)}
                        {(context.getActive === "مرکز پشتیبانی") ? (
                            <Container maxWidth="xl">

                                <Row className='d-flex flex-row align-items-center justify-content-between'>
                                    <Row className='d-flex flex-row align-items-center justify-content-start pt-3 pb-3' style={{ marginLeft: "0px", marginRight: "0px" }} gutter={24}>
                                        <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'ارسال پیام جدید' ? "selected-bg" : "un-selected-bg"}`}>
                                            <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                                context.setTicketModal(true);
                                            }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>ارسال پیام جدید</p></Button>
                                        </Col>
                                        <Col className={`d-flex flex-row align-items-center justify-content-center ${context.getSubMenuActive === 'پیام های من' ? "selected-bg" : "un-selected-bg"}`}>
                                            <Link to="/my-tickets"> <Button onClick={(e) => {
                                                context.setSubMenuActive(e.target.innerText)
                                            }}><p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>پیام های من</p></Button></Link>
                                        </Col>
                                    </Row>
                                    {context.getSettings !== null ? (<Row>
                                        <Button style={{ backgroundColor: "#FFDB29" }}><p className='mb-0' style={{ fontSize: "13px", color: "#FFFFFF" }}>{context.getSettings.phone}</p><img style={{ width: "16px", height: "16px", marginRight: "10px" }} src={window.origin + "/User/phone.svg"} />
                                        </Button>
                                    </Row>) : null}

                                </Row>
                            </Container>
                        ) : (null)}
                    </Row>
                </Paper>
                <Paper elevation={0} sx={{ display: { xs: "flex", sm: "flex", md: 'flex', xl: 'none', lg: "none" } }} className='w-100 align-items-center' style={{ position: "sticky", top: "0px", zIndex: 99, backgroundColor: "white", height: '60px' }}>
                    <Container maxWidth="xl">
                        <Row className='d-flex flex-row align-items-center justify-content-center' align={"middle"} justify={"center"}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "right" }}>
                                <IconButton
                                    onClick={(e) => {
                                        context.setMainDrawer(true)
                                    }}
                                    style={{ padding: "0px" }}
                                >
                                    <img style={{ width: "32px", height: "32px" }} src={window.origin + '/User/menu.svg'} />
                                </IconButton>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                <Link to="/dashboard"><img style={{ width: "32px", height: "32px" }} src={window.origin + '/User/logo_tablighat_dar_google.webp'} /></Link>
                            </Col>
                            {getUser !== null ? (<Col xs={8} sm={8} md={8} lg={8} xl={8} className='d-flex flex-row align-items-center justify-content-end'>
                                <div onClick={() => {
                                    context.setLeftDrawer(true)
                                }}>
                                    <Avatar variant="square" {...stringAvatar(`${getUser.first_name} ${getUser.last_name}`)} />

                                </div>
                            </Col>) : (null)}
                        </Row>
                    </Container>
                </Paper>
                {children}
</Grid>
<Grid  item xs={12} sm={12} md={12} lg={12} xl={12} style={{display:"flex",alignItems:"end"}}>
{(context.getSettings !== null) ? (<Container className='footer' disableGutters maxWidth={false} >
                    <Container maxWidth="xl" style={{ marginTop: "45px" }}>
                        <Row className='d-flex flex-row align-items-center justify-content-center'>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                                <h2 style={{ letterSpacing: "2px" }}>اعتماد شما را از دست نخواهیم داد</h2>
                                <p style={{ letterSpacing: "1px" }}>ما اینجاییم تا به رشد برند شما کمک کنیم</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "left" }} className='d-flex flex-row-reverse align-items-center justify-content-start'>
                                <div style={{ borderLeft: "5px solid white" }}>
                                    <img style={{ width: "64px", height: "64px", marginLeft: "15px" }} src={window.origin + "/User/support.svg"} />

                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                    <p style={{ fontSize: "20px" }}>{context.getSettings.phone}</p>
                                    <p className='mb-0'>7 روز هفته، پاسخگوی شما هستیم</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <hr style={{ borderTop: "2px dashed white" }} />
                    <Container maxWidth="xl" style={{ marginTop: "16px" }}>
                        <Row className='d-flex flex-row align-items-center justify-content-center mb-3'>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                                <Row className='d-flex flex-row align-items-center justify-content-start' gutter={16}>
                                    <Col>صفحه ی نخست</Col>
                                    <Col>درباره ی ما</Col>
                                    <Col>قوانین</Col>
                                    <Col>تماس با ما</Col>
                                </Row>
                            </Col>
                            <br />
                            <br />
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                <Row className='d-flex flex-row-reverse align-items-center justify-content-start' gutter={16}>
                                    <Col><Link to={context.getSettings.instagram} ><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/insta.svg"} /></Link></Col>
                                    <Col><Link to={context.getSettings.telegram} ><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/telegram.svg"} /></Link></Col>
                                    <Col><Link to={context.getSettings.youtube} ><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/yout.svg"} /></Link></Col>
                                    <Col><Link to={context.getSettings.linkedin} ><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/linkedin.svg"} /></Link></Col>
                                    <Col><Link to={context.getSettings.x_app} ><img style={{ width: "16px", height: "16px" }} src={window.origin + "/User/x.svg"} /></Link></Col>
                                </Row>
                            </Col>

                        </Row>
                    </Container>
                </Container>) : (null)}

</Grid>
                
            </Grid>

        </>

    );
};
export default Index;