import React, { useContext, useEffect } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import Layout from '../Layout/Index';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Index = () => {
    const context = useContext(MahabadContext)
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    return (
        <Layout>
            <Container maxWidth="xl" style={{ marginBottom: "140px" }}>
                <Row className='d-flex flex-row align-items-center justify-content-betweenmb-3'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className='mt-4 '>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography color="text.primary">یوتیوب</Typography>
                                <Typography color="text.primary">لیست کانال های یوتیوب</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "left" }} className='mt-4 '>
                        <Row className='d-flex flex-row align-items-center justify-content-end mb-4' gutter={16}>
                            <Col xs={12} sm={8} md={8} lg={6} xl={6}>
                                <Button component={Link} className='btnM' to="/addChannel"> افزودن کانال جدید</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <div className='card-service mt-3'>
                                    <div className='channelsDiv col-12'>
                                        <img style={{ width: "100px", height: "100px" }} src={window.origin + "/User/gg.svg"} />
                                    </div>
                                    <div className='mt-3'><p style={{ textAlign: "right", fontSize: "16px", fontWeight: 600, letterSpacing: "2px" }}>نام کانال : اسماعیل چنل</p></div>
                                    <Row className='d-flex flex-row align-items-center justify-content-center' gutter={16}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Row className='align-items-center justify-content-start mt-2' style={{ gap: "16px", flexDirection: "row" }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Button className='btnDan'>تایید نشده</Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Button className='btnI'><RemoveRedEyeIcon fontSize='small' /> مشاهده</Button>
                                                </Col>


                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Row className='align-items-center justify-content-start mt-2' style={{ gap: "16px", flexDirection: "row" }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Button className='btnI'><RemoveRedEyeIcon fontSize='small' /> آمار کانال</Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Button className='btnI'><RemoveRedEyeIcon fontSize='small' /> تسویه حساب های من</Button>
                                                </Col>

                                            </Row></Col>
                                    </Row>

                                </div>
                            </Col>

                        </Row>
                    </Col>

                </Row>
            </Container>
        </Layout>
    );
};
export default Index;