import React, { useContext, useEffect, useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import Layout from '../Layout/Index';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from "react-router-dom";

const Index = () => {
    const context = useContext(MahabadContext)
    const [corency, setCorency] = useState("dollar")
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    
    useEffect(() => {
        if(!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === ""){
            navigate('/');
        }else{
            context.handleGetServicesByParent(params.get("id"))
        }
      
    }, [params.get("id")])
    return (
        <Layout>
            <Container maxWidth="xl">
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4 mb-3' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <span
                                >
                                    خرید اکانت
                                </span>
                                <Typography color="text.primary">{context.getSubMenuActive}</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-center justify-content-end mt-4' gutter={16}>
                    {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Row className='d-flex flex-row align-items-center justify-content-start mb-4' gutter={16}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button className='btn-prim'>خرید اکانت اختصاصی</Button>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button className='btn-sec'>خرید اکانت مدیریت شده</Button>
                            </Col>
                        </Row>
                    </Col> */}
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: "left" }}>
                        <Row className='d-flex flex-row align-items-center justify-content-end mb-4' gutter={16}>
                            <Col xs={8} sm={8} md={8} lg={6} xl={6}>
                                <Button onClick={() => {
                                    setCorency("dollar")
                                }} className={corency === "dollar" ? "btn-prim1" : "btn-sec2"}><img style={{ width: "32px", height: "32px", marginLeft: "15px" }} src={window.origin + "/User/dollar.svg"} /> دلار</Button>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={6} xl={6}>
                                <Button onClick={() => {
                                    setCorency("turkish_lir")
                                }} className={corency === "turkish_lir" ? "btn-prim1" : "btn-sec2"}><img style={{ width: "32px", height: "32px", marginLeft: "15px" }} src={window.origin + "/User/tle.svg"} /> لیر</Button>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={6} xl={6}>
                                <Button onClick={() => {
                                    setCorency("derham")
                                }} className={corency === "derham" ? "btn-prim1" : "btn-sec2"}><img style={{ width: "32px", height: "32px", marginLeft: "15px" }} src={window.origin + "/User/dh.svg"} /> درهم</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                            {(context.getServices !== null) ? (context.getServices.map(item=>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <div className='card-service mt-3'>
                                    <img style={{ width: "100px", height: "100px" }} src={Config.adminPics + item.photo} />
                                    {corency === "dollar" ? <h3 style={{ color: "#7E829B", marginTop: "30px", marginBottom: "20px" }}>{item.dollar_price}<span style={{ fontSize: "16px" }}>دلار</span></h3> : null}
                                    {corency === "derham" ? <h3 style={{ color: "#7E829B", marginTop: "30px", marginBottom: "20px" }}>{item.derham_price}<span style={{ fontSize: "16px" }}>درهم</span></h3> : null}
                                    {corency === "turkish_lir" ? <h3 style={{ color: "#7E829B", marginTop: "30px", marginBottom: "20px" }}>{item.turkish_lir_price}<span style={{ fontSize: "16px" }}>لیر</span></h3> : null}
                                
                                    <span className='percentBox'>{item.tax} درصد کارمزد</span>
                                    <div className='mt-5 mb-5' style={{ textAlign: "right" }}>
                                        <pre style={{ color: '#7E829B' }}>
                                            {`${item.description}`}
                                        </pre>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <div className='mt-3' style={{ textAlign: "center" }}>
                                        {(context.getSettings !== null) ? (<>
                                            {corency === "dollar" ? <h3 style={{ color: "#7E829B", marginTop: "30px", marginBottom: "20px" }}>{`${Math.ceil((item.dollar_price * context.getSettings.dollar_price +  (item.tax / 100 * (item.dollar_price * context.getSettings.dollar_price))) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}<span style={{ fontSize: "16px" }}>تومان</span></h3> : null}
                                    {corency === "derham" ? <h3 style={{ color: "#7E829B", marginTop: "30px", marginBottom: "20px" }}>{`${Math.ceil((item.derham_price * context.getSettings.derham_price +  (item.tax / 100 * (item.derham_price * context.getSettings.derham_price))) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}<span style={{ fontSize: "16px" }}>تومان</span></h3> : null}
                                    {corency === "turkish_lir" ? <h3 style={{ color: "#7E829B", marginTop: "30px", marginBottom: "20px" }}>{`${Math.ceil((item.turkish_lir_price * context.getSettings.turkish_lir_price +  (item.tax / 100 * (item.turkish_lir_price * context.getSettings.turkish_lir_price))) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}<span style={{ fontSize: "16px" }}>تومان</span></h3> : null}
                                        </>) : (null)}
                                  
                                        

                                        <Button component={Link} to={`/buyService?id=${item._id}&corency=${corency}`} className='btn-ak'>خرید اکانت</Button>
                                    </div>
                                </div>
                            </Col>
                                )) : (<Col xs={24} sm={24} md={24} lg={24} xl={24}><div className='div_err'> 
                                    <p className='mb-0' style={{textAlign:"center"}}>متاسفانه سرویسی در این دسته بندی وجود ندارد</p>
                                    </div></Col>)}
                            

                        </Row>
                    </Col>

                </Row>
            </Container>
        </Layout>
    );
};
export default Index;