import * as React from "react";
import * as ReactDOM from "react-dom";

import UserLogin from './User/Login/Index';
import UserRegister from './User/Register/Index';
import Profile from './User/Profile/Index';
import AddChannel from './User/AddChannel/Index';
import Channels from './User/Channels/Index';
import MyFactors from './User/MyFactors/Index';
import Mypayments from './User/MyPayments/Index';
import UserDashboard from './User/Dashboard/Index';
import RenewAccount from './User/RenewAccount/Index';
import Services from './User/Services/Index';
import BuyService from './User/BuyService/Index';
import MyOrders from './User/MyOrders/Index';
import MyTickets from './User/MyTickets/Index';
import Verify from './User/VerifyPhone/Index';
import ViewTicket from './User/ViewTicket/Index';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import GlobalState from "./GlobalState";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/verify",
    element: <Verify />
  },
  {
    path: "/view-ticket",
    element: <ViewTicket />
  },
  {
    path: "/renew-account",
    element: <RenewAccount />
  },
  {
    path: "/my-payments",
    element: <Mypayments />
  },
  {
    path: "/my-factors",
    element: <MyFactors />
  },
  {
    path: "/channels",
    element: <Channels />
  },
  {
    path: "/addChannel",
    element: <AddChannel />
  },
  {
    path: "/profile",
    element: <Profile />
  },
  {
    path: "/my-tickets",
    element: <MyTickets />
  },
  {
    path: "/my-orders",
    element: <MyOrders />
  },
  {
    path: "/dashboard",
    element: <UserDashboard />
  },
  {
    path: "/services",
    element: <Services />
  },
  {
    path: "/buyService",
    element: <BuyService />
  },
  {
    path: "/login",
    element: <UserLogin />
  },
  {
    path: "/*",
    element: <UserLogin />
  },
  {
    path: "/register",
    element: <UserRegister />
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <GlobalState>
<RouterProvider router={router} />
  </GlobalState>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
