import React, { useState, useRef } from 'react';
import axios from "axios";
import MahabadContext from "./Context/MainContext";
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Config from './Config/config.json';


let ToastifyOptions = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const GlobalState = props => {
    const [, forceUpdate] = useState();
    SimpleReactValidator.addLocale('fa', {
        accepted: ':attribute مورد قبول قرار نگرفت',
        after: ':attribute باید تاریخی بعد از :date باشد.',
        after_or_equal: ':attribute باید تاریخی برابر یا بعد از :date باشد.',
        alpha: ":attribute باید شامل حروف انگلیسی باشد.",
        alpha_space: ":attribute باید شامل حروف انگلیسی و فاصله باشد.",
        alpha_num: ":attribute باید شامل اعداد و حروف انگلیسی باشد.",
        alpha_num_space: ":attribute باید شامل اعداد و حروف انگلیسی و فاصله باشد.",
        alpha_num_dash: ":attribute باید شامل اعداد و حروف انگلیسی و خط تیره.",
        alpha_num_dash_space: ":attribute باید شامل اعداد و حروف انگلیسی، خط تیره و فاصله باشد.",
        array: ":attribute باید یک آرایه باشد.",
        before: ":attribute باید قبل از :date باشد.",
        before_or_equal: ":attribute باید قبل یا برابر با :date باشد.",
        between: ":attribute باید بین :min و :max:type باشد.",
        boolean: ":attribute باید یک بول باشد.",
        card_exp: ":attribute باید یک تاریخ انقضا معتبر باشد.",
        card_num: ":attribute باید یک شماره کارت اعتباری معتبر باشد.",
        currency: ":attribute باید یک ارز معتبر باشد.",
        date: ":attribute باید تاریخ باشد.",
        date_equals: ":attribute باید در :date بازه باشد.",
        email: ":attribute بایدآدرس ایمیل معتبر باشد.",
        in: ":attribute باید یکی از :values باشد.",
        integer: ":attribute باید یک عدد صحیح باشد.",
        max: ":attribute باید کمتر از :max:type باشد.",
        min: ":attribute باید بیشتر از :min:type باشد.",
        not_in: ":attribute نباید مشابه با :values باشد.",
        not_regex: ":attribute نباید با الگوی مورد نیاز مطابقت داشته باشد.",
        numeric: ":attribute باید عدد باشد.",
        phone: ":attribute باید یک شماره تلفن معتبر باشد.",
        regex: ":attribute باید با الگوی مورد نیاز مطابقت داشته باشد.",
        required: "وارد کردن :attribute الزامی است.",
        size: ":attribute باید تبدیل شود به size:type:.",
        string: ":attribute باید یک رشته باشد.",
        typeof: ":attribute نوع صحیح :type نوع نیست.",
        url: ":attribute باید یک آدرس اینترنتی باشد.",
    });
    const validator = useRef(
        new SimpleReactValidator({
            locale: 'fa',
            element: message => <div style={{ color: "#ff9e9e", fontSize: "14px" }}>{message}</div>
        })
    );
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = document.cookie;
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const [getLoading, setLoading] = useState(false);
    const [getActive, setActive] = useState("داشبورد")
    const [getSubMenuActive, setSubMenuActive] = useState("داشبورد")
    const [getTicketModal, setTicketModal] = useState(false)
    const [leftDrawer, setLeftDrawer] = useState(false);
    const [mainDrawer, setMainDrawer] = useState(false);
    const [getSettings, setSettings] = useState(null);
    const [getServices, setServices] = useState(null);
    const [getTickets, setTickets] = useState(null);
    const [getService, setService] = useState(null);
    const [getPayments, setPayments] = useState(null);
    const [getSuccessPayments, setSuccessPayments] = useState(null);
    
    const handleGetAllPages = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/pages/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }
    
    const handleGetAllPayments = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.userApi}/payments/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("userToken"))
            }
        }).then(res => {
            setPayments(res.data)
        }).catch((err) => {
            setPayments(null)
        })
    }
    const handleGetAllSuccessPayments = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.userApi}/SuccessPayments/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("userToken"))
            }
        }).then(res => {
            setSuccessPayments(res.data)
        }).catch((err) => {
            setSuccessPayments(null)
        })
    }
    const handleGetServicesByParent = async (serviceName) => {
           let page = 1
           let count = 100
        await axios.get(`${Config.userApi}/services/${page}/${count}/${serviceName}`).then(res => {
            setServices(res.data)
        }).catch((err) => {
            setServices(null)
        })
    }
    const handleGetServicesById = async (serviceId) => {
        await axios.get(`${Config.userApi}/services/byId/${serviceId}`).then(res => {
            setService(res.data)
        }).catch((err) => {
            setService(null)
        })
    }
    const handleGetAllTickets = async () => {
           let page = 1
           let count = 100
        await axios.get(`${Config.userApi}/tickets/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("userToken"))
            }
        }).then(res => {
            setTickets(res.data)
        }).catch((err) => {
            setTickets(null)
        })
    }
    const handleGetAllSettings = async () => {
        await axios.get(`${Config.userApi}/settings`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("userToken"))
            }
        }).then(res => {
            setSettings(res.data)
        }).catch((err) => {
            setSettings(null)
        })
    }
    const store = {
        handleGetAllSuccessPayments,getSuccessPayments:getSuccessPayments,setSuccessPayments,getPayments:getPayments,setPayments,handleGetAllPayments,handleGetAllSettings,handleGetServicesById,getService:getService,setService,handleGetAllTickets,getTickets:getTickets,setTickets,getServices:getServices,setServices,handleGetServicesByParent,getSettings:getSettings,setSettings,mainDrawer:mainDrawer,setMainDrawer,leftDrawer: leftDrawer, setLeftDrawer, getTicketModal: getTicketModal, setTicketModal, forceUpdate, validator:validator, setCookie, getCookie, getLoading: getLoading, setLoading, getActive: getActive, setActive, getSubMenuActive: getSubMenuActive, setSubMenuActive, handleGetAllPages
    };

    return (
        <MahabadContext.Provider value={store}>
            {props.children}
        </MahabadContext.Provider>
    );
};

export default GlobalState;

