import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MahabadContext from '../../Context/MainContext';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

export default function AccountMenu() {
    const context = useContext(MahabadContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        context.setSubMenuActive("ابزارهای سئو")
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    className='w-100'
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <p className='mb-0' style={{ fontSize: "13px", color: "#000000" }}>ابزارهای سئو</p>
                </IconButton>
            </Box>

            <Menu
                style={{ direction: "rtl" }}
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem component={Link} to="/services?id=semrush" className='menuTexts' onClick={handleClose}>
                    خرید ابزار SEMrush
                </MenuItem>
                <MenuItem component={Link} to="/services?id=ahrefs" className='menuTexts' onClick={handleClose}>
                    خرید ابزار Ahrefs
                </MenuItem>
                <MenuItem component={Link} to="/services?id=moz-pro" className='menuTexts' onClick={handleClose}>
                    خرید ابزار MOZ Pro
                </MenuItem>
                <MenuItem component={Link} to="/services?id=similarweb" className='menuTexts' onClick={handleClose}>
                    خرید ابزار Similarweb
                </MenuItem>
                <MenuItem component={Link} to="/services?id=woorank" className='menuTexts' onClick={handleClose}>
                    خرید ابزار WooRank
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}