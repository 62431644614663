import React, { useContext, useEffect , useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { Layout, Row, Col, theme, Typography, Input, message, Checkbox  } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import axios from 'axios';

const Index = () => {
    const context = useContext(MahabadContext)
    const [getPhone, setPhone] = useState(null)
    const [getCode, setCode] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    
    const handleVerify = async() => {
        if (context.validator.current.allValid()) {
            await axios.get(`${Config.userApi}/verifyPhone/${JSON.parse(context.getCookie("phone"))}/${getCode}`).then(res => {
                messageApi.open({
                    type: 'success',
                    content: 'شماره موبایل شما با موفقیت تایید شد در حال انتقال ...',
                });
                context.setCookie("userToken",res.headers['x-auth-token'])
                setTimeout(()=>{
                    navigate('/dashboard');
                },[2000])
            }).catch((err) => {
                messageApi.open({
                    type: 'error',
                    content: err.response.data.message,
                });
            })
        } else {
            context.validator.current.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            context.forceUpdate(1);
        }
    }
    return (
        <div className='d-flex flex-row align-items-center justify-content-center' style={{ backgroundColor: "#F2F3F7", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center', direction: "rtl" }}>
            {contextHolder}
            <Col xs={22} sm={22} md={22} lg={12} xl={12} className='bg-wh'>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "center" }}>
                    {(context.getSettings !== null) ? (<img style={{ height: "64px", width: "64px" }} src={Config.adminPics + context.getSettings.logo} />) : (<img style={{ height: "64px", width: "64px" }} src={window.origin + "/User/logo.svg"} />)}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>کد تایید <span style={{ color: "red" }}>*</span></p>
                    <Input min="4" max="4" type="number" onChange={(e) => {
                        setCode(e.target.value)
                        context.validator.current.showMessageFor("کد تایید");
                    }} name="کد تایید" size="large" placeholder="کد تایید خود را وارد کنید" prefix={<PhoneOutlined />} />
                    {context.validator.current.message(
                        "کد تایید",
                        getCode,
                        "required|min:4|max:4"
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <Button onClick={(e) => {
                        handleVerify()
                    }} className='btn-block btn-primary mb-3'>تایید شماره موبایل</Button>
                </Col>
            </Col>
        </div>
    
    );
};
export default Index;