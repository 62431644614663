import React, { useContext, useEffect } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import Layout from '../Layout/Index';
import Inputo from '../BuyService/input';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ReportIcon from '@mui/icons-material/Report';
import { styled } from '@mui/material/styles';
import { InputGroup } from 'react-bootstrap';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tableo from "./Table"
import Uploado from './Uploado';
import ListItem1 from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Index = () => {
    const context = useContext(MahabadContext)
    const [chipData, setChipData] = React.useState([
        { label: 'Angular' },
        { label: 'jQuery' },
        { label: 'Polymer' },
        { label: 'React' },
        { label: 'Vue.js' },
    ]);
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete.label));
    };

    return (
        <Layout>
            <Container maxWidth="xl" style={{ marginBottom: "100px" }}>
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/material-ui/getting-started/installation/"
                                >
                                   یوتیوب
                                </Link>
                                <Typography color="text.primary">افزودن کانال جدید</Typography>
                            </Breadcrumbs>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-start justify-content-between mt-2 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <div className='mt-3'>
                            <Button className='d-flex flex-row align-items-center justify-content-start btn-timeLine'>
                                <div style={{ backgroundColor: "#00734e", width: "32px", height: "32px", borderRadius: "100px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <p className='mb-0' style={{ fontSize: "16px", color: "white" }}>1</p>
                                </div>
                                <p className='mb-0' style={{ marginRight: "16px" }}>ثبت کانال</p>
                            </Button>
                            <Button className='d-flex flex-row align-items-center justify-content-start btn-timeLine2 mt-2'>
                                <div style={{ backgroundColor: "rgba(63,66,84,.6)", width: "32px", height: "32px", borderRadius: "100px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <p className='mb-0' style={{ fontSize: "16px", color: "white" }}>2</p>
                                </div>
                                <p className='mb-0' style={{ marginRight: "16px" }}>تکمیل اطلاعات</p>
                            </Button>
                            <Button className='d-flex flex-row align-items-center justify-content-start btn-timeLine2 mt-2'>
                                <div style={{ backgroundColor: "rgba(63,66,84,.6)", width: "32px", height: "32px", borderRadius: "100px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <p className='mb-0' style={{ fontSize: "16px", color: "white" }}>3</p>
                                </div>
                                <p className='mb-0' style={{ marginRight: "16px" }}>تایید پیش نویس قرارداد</p>
                            </Button>
                            <Button className='d-flex flex-row align-items-center justify-content-start btn-timeLine2 mt-2'>
                                <div style={{ backgroundColor: "rgba(63,66,84,.6)", width: "32px", height: "32px", borderRadius: "100px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <p className='mb-0' style={{ fontSize: "16px", color: "white" }}>4</p>
                                </div>
                                <p className='mb-0' style={{ marginRight: "16px" }}>اتصال سایت تبلیغات در گوگل به کانال شما</p>
                            </Button>
                            <Button className='d-flex flex-row align-items-center justify-content-start btn-timeLine2 mt-2'>
                                <div style={{ backgroundColor: "rgba(63,66,84,.6)", width: "32px", height: "32px", borderRadius: "100px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <p className='mb-0' style={{ fontSize: "16px", color: "white" }}>5</p>
                                </div>
                                <p className='mb-0' style={{ marginRight: "16px" }}>فروراد اتوماتیک پنل</p>
                            </Button>
                            <Button className='d-flex flex-row align-items-center justify-content-start btn-timeLine2 mt-2'>
                                <div style={{ backgroundColor: "rgba(63,66,84,.6)", width: "32px", height: "32px", borderRadius: "100px" }} className='d-flex flex-row align-items-center justify-content-center'>
                                    <p className='mb-0' style={{ fontSize: "16px", color: "white" }}>6</p>
                                </div>
                                <p className='mb-0' style={{ marginRight: "16px" }}>تکمیل اتصال ادسنس</p>
                            </Button>
                        
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className='card-service mt-3'>
                                    <Row className="d-flex flex-row align-items-start justify-content-center" gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                            <div className='alerto'><ReportIcon style={{color:"#317151"}} />در این مرحله نیاز است فرم زیر را تکمیل کنید. </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>نام کانال <span className='text-danger'>*</span></p>
                                                    <input className='into' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>لینک کانال <span className='text-danger'>*</span></p>
                                                    <input className='into' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column mb-3'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>اسکرین ‌شات از داشبورد یوتیوب استودیو <span className='text-danger'>*</span></p>
                                                    <Uploado />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column mb-3'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>اسکرین‌ شات از آنالیتیکس یوتیوب استودیو <span className='text-danger'>*</span></p>
                                                    <Uploado />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column mb-3'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>اسکرین شات از صفحه محتوا با فیلتر کپی رایت کلیم <span className='text-danger'>*</span></p>
                                                    <Uploado />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                            <Row gutter={16} className='mt-4'>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column mb-3'>
                                                    <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>اسکرین ‌شات از مانیتیزیشن یوتیوب استودیو <span className='text-danger'>*</span></p>
                                                    <Uploado />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                            <Button className='btnM'>ثبت کانال جدید</Button>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Layout>
    );
};
export default Index;