import React, { useContext, useEffect, useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { Layout, Row, Col, theme, Typography, Input, message, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import axios from 'axios';

const Index = () => {
    const context = useContext(MahabadContext)
    const [getPhone, setPhone] = useState(null)
    const [getPassword, setPassword] = useState(null)
    const [getTerms, setTerms] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const handleLogin = async () => {
        if (context.validator.current.allValid()) {
            let body = {
                "phone": getPhone,
                "password": getPassword
            }
            await axios.post(`${Config.userApi}/login`, body).then(res => {
                context.setCookie('userToken',JSON.stringify(res.headers["x-auth-token"]))
                context.setCookie('user',JSON.stringify(res.data))
                messageApi.open({
                    type: 'success',
                    content: 'ورود شما با موفقیت انجام شد در حال انتقال ...',
                });
                setTimeout(() => {
                    navigate('/dashboard');
                }, [2000])
            }).catch(async (err) => {
                if (err.response.data.message === "شما هنوز موبایل خود را تایید نکردید") {
                    context.setCookie("phone", JSON.stringify(getPhone))
                    await axios.get(`${Config.userApi}/send_code/${getPhone}`).then((e) => {
                        navigate('/verify');
                    }).catch((ex) => {
                        console.log(ex)
                    })
                } else {
                    messageApi.open({
                        type: 'error',
                        content: err.response.data.message,
                    });
                }
            })
        } else {
            context.validator.current.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            context.forceUpdate(1);
        }
    }
    useEffect(() => {
        if ((context.getCookie("userToken") !== undefined) && context.getCookie("userToken") !== "null" && context.getCookie("userToken") !== null && context.getCookie("userToken") !== ""){
            navigate("/dashboard")
        }
    }, [])
    return (
        <div className='d-flex flex-row align-items-center justify-content-center' style={{ backgroundColor: "#F2F3F7", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center', direction: "rtl" }}>
            {contextHolder}
            <Col xs={22} sm={22} md={22} lg={12} xl={12} className='bg-wh'>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "center" }}>
                    {(context.getSettings !== null) ? (<img style={{ height: "64px", width: "64px" }} src={Config.adminPics + context.getSettings.logo} />) : (<img style={{ height: "64px", width: "64px" }} src={window.origin + "/User/logo.svg"} />)}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>شماره موبایل <span style={{ color: "red" }}>*</span></p>
                    <Input type="number" onChange={(e) => {
                        setPhone(e.target.value)
                        context.validator.current.showMessageFor("شماره موبایل");
                    }} name="شماره موبایل" size="large" placeholder="شماره موبایل خود را وارد کنید" prefix={<PhoneOutlined />} />
                    {context.validator.current.message(
                        "شماره موبایل",
                        getPhone,
                        "required|min:11|max:11"
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <p style={{ fontSize: "13px", textAlign: "right", marginBottom: "5px", marginTop: "32px" }}>رمز عبور <span style={{ color: "red" }}>*</span></p>
                    <Input onChange={(e) => {
                        setPassword(e.target.value)
                        context.validator.current.showMessageFor("رمز عبور");
                    }} type="password" name="رمز عبور" size="large" placeholder="رمز عبور خود را وارد کنید" prefix={<LockOutlined />} />
                    {context.validator.current.message(
                        "رمز عبور",
                        getPassword,
                        "required|min:3"
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right", marginTop: "16px" }}>
                    <Checkbox><span className='text-primary' style={{ fontWeight: "600" }}>مرا بخاطر بسپار</span></Checkbox>
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                    <Button onClick={(e) => {
                        handleLogin()
                    }} className='btn-block btn-primary mb-3'>ورود</Button>
                    <p style={{ fontSize: "13px", textAlign: "center", marginBottom: "5px", marginTop: "10px", color: "#b5b5c3" }}>حساب کاربری ندارید؟ <Link to="/register" className='text-primary' style={{ fontWeight: 600, fontSize: "14px" }}>ایجاد کنید</Link></p>
                </Col>
            </Col>
        </div>

    );
};
export default Index;