import React, { useContext, useEffect, useState } from 'react';
import MahabadContext from '../../Context/MainContext';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';
import { Button, Container } from '@mui/material';
import Chip from '@mui/material/Chip';
import Layout from '../Layout/Index';
import Inputo from '../BuyService/input';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import axios from 'axios';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Index = () => {
    const context = useContext(MahabadContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [getSite, setSite] = useState("https://example.com")
    const [getTitr1, setTitr1] = useState("تیتر اول خود را وارد کنید")
    const [getTitr2, setTitr2] = useState("تیتر دوم خود را وارد کنید")
    const [getTitr3, setTitr3] = useState("تیتر سوم خود را وارد کنید")
    const [getDesc1, setDesc1] = useState("توضیحات اول خود را وارد کنید")
    const [getDesc2, setDesc2] = useState("توضیحات دوم خود را وارد کنید")
    const [getWeb, setWeb] = useState(true)
    const params = new URLSearchParams(location.search);
    const [chipData, setChipData] = React.useState([
    ]);
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete.label));
    };
    const hanldeCheckoutBasket = async () => {
        let body = {
            "serviceId": context.getService._id,
            "site": getSite,
            "titr_1": getTitr1,
            "titr_2": getTitr2,
            "titr_3": getTitr3,
            "desc_1": getDesc1,
            "desc_2": getDesc2,
            "keywords": chipData,
            "payment_type": params.get("corency"),
        }
        await axios.post(`${Config.userApi}/checkout`, body , {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("userToken"))
            }
        }).then(res => {
          window.location.assign(res.data.url)
        }).catch( (err) => {
      console.log(err)
        })
    }
    useEffect(() => {
        if(!context.getCookie('user') || context.getCookie('user') === 'null' || context.getCookie('user') === null || context.getCookie('user') === ""){
            navigate('/');
        }else{
            context.handleGetServicesById(params.get("id"))
        }
       
    }, [params.get("id")])
    return (
        <Layout>
            <Container maxWidth="xl">
                <Row className='d-flex flex-row align-items-center justify-content-between mt-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div role="presentation" onClick={handleClick}>
                            {(context.getService !== null) ? (<Breadcrumbs aria-label="breadcrumb">
                                <Link

                                >
                                    خرید اکانت
                                </Link>
                                <Link
                                >
                                    {context.getService.name}
                                </Link>
                                {params.get("corency") === "turkish_lir" ? (<Typography color="text.primary">پلن {context.getService.turkish_lir_price} لیر</Typography>) : (null)}
                                {params.get("corency") === "dollar" ? (<Typography color="text.primary">پلن {context.getService.dollar_price} دلار</Typography>) : (null)}
                                {params.get("corency") === "derham" ? (<Typography color="text.primary">پلن {context.getService.derham_price} درهم</Typography>) : (null)}

                            </Breadcrumbs>) : (null)}

                        </div>
                    </Col>
                </Row>
                {context.getService !== null ? (<Row className='d-flex flex-row align-items-center justify-content-between mt-2 mb-4' gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row className='d-flex flex-row align-items-start justify-content-start' gutter={16}>
                            {context.getService.category === "google" ? (<Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <div className='card-service mt-3'>
                                    <div className='d-flex flex-row align-items-center justify-content-start'>
                                        <div><img style={{ width: "40px", height: "40px" }} src={Config.adminPics + context.getService.photo} /></div>
                                        <div className='d-flex flex-column align-items-start justify-content-start' style={{ marginRight: "10px" }}>
                                            <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: 600 }}>{context.getService.name}</p>
                                            {params.get("corency") === "turkish_lir" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.turkish_lir_price} لیر</p>) : (null)}
                                            {params.get("corency") === "dollar" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.dollar_price} دلار</p>) : (null)}
                                            {params.get("corency") === "derham" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.derham_price} درهم</p>) : (null)}

                                        </div>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <p style={{ fontSize: "14px", fontWeight: 600, textAlign: "right" }}><img style={{ width: "5px", height: "5px" }} src={window.origin + "/User/doti.svg"} /> {context.getService.name}</p>
                                    <Row gutter={16} className='mt-4'>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>لینک سایت <span className='text-danger'>*</span></p>
                                            <input onChange={(e) => {
                                                setSite(e.target.value)
                                            }} value={getSite} className='into' />
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>تیتر 1 </p>
                                            <input onChange={(e) => {
                                                setTitr1(e.target.value)
                                            }} value={getTitr1} className='into' />
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>تیتر 2</p>
                                            <input onChange={(e) => {
                                                setTitr2(e.target.value)
                                            }} value={getTitr2} className='into' />
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>تیتر 3 </p>
                                            <input onChange={(e) => {
                                                setTitr3(e.target.value)
                                            }} value={getTitr3} className='into' />
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>توضیحات 1 </p>
                                            <input onChange={(e) => {
                                                setDesc1(e.target.value)
                                            }} value={getDesc1} className='into' />
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>توضیحات 2</p>
                                            <input onChange={(e) => {
                                                setDesc2(e.target.value)
                                            }} value={getDesc2} className='into' />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>کلمات کلیدی</p>
                                            <input onKeyDown={(event) => {
                                                if (event.key === "Enter" && event.target.value !== "" && event.target.value !== " ") {
                                                    setChipData([...chipData, { label: event.target.value }])
                                                    event.target.value = ""
                                                    // کلید Enter فشرده شده است
                                                    // اینجا می‌توانید کارهای مورد نظرتان را انجام دهید
                                                    console.log("کلید Enter فشرده شد");
                                                }
                                            }} className='into' />
                                            <Col className='d-flex flex-row align-items-center justify-content-start'>
                                                <div style={{
                                                    marginTop: "10px",
                                                    marginBottom: "20px",
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexWrap: 'wrap',
                                                    listStyle: 'none',
                                                    p: 0.5,
                                                    m: 0,
                                                    direction: "ltr"
                                                }}>
                                                    {chipData.map((data) => {
                                                        let icon;
                                                        return (
                                                            <ListItem key={data.label}>
                                                                <Chip
                                                                    icon={icon}
                                                                    label={data.label}
                                                                    onDelete={handleDelete(data)}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </div></Col>

                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='d-flex flex-row justify-content-start w-100'>
                                            <Row gutter={16} className='d-flex flex-row align-items-center w-100'>
                                                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                                    <Button onClick={(e) => {
                                                        setWeb(true)
                                                    }} className={getWeb === true ? 'btn-prim' : 'btn-sico'}><img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/desktop.svg"} /> نمایش در دسکتاپ</Button>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                                    <Button onClick={(e) => {
                                                        setWeb(false)
                                                    }} className={getWeb === false ? 'btn-prim' : 'btn-sico'}> <img style={{ width: "16px", height: "16px", marginLeft: "10px" }} src={window.origin + "/User/mobile.svg"} /> نمایش در موبایل</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {getWeb === true ? (<Col xs={24} sm={24} md={24} lg={24} xl={24} className='mt-4'>
                                            <div style={{ backgroundColor: "#F5F8FA", padding: "10px", borderRadius: "10px" }}>
                                                <div className='d-flex flex-column align-items-end justify-content-center' style={{ padding: "20px", backgroundColor: "white", borderRadius: "10px" }}>
                                                    <p><span style={{ fontSize: "16px", fontWeight: 600, marginRight: "5px", color: "#222032" }}>Ad.</span>{getSite}</p>
                                                    <p style={{ color: "#3aa9ff" }}>{getTitr1} | {getTitr2} | {getTitr3}</p>
                                                    <p className='mb-1' style={{ color: "#7e829b" }}>{getDesc1} | {getDesc2}</p>
                                                </div>
                                            </div>
                                        </Col>) : (<Col xs={24} sm={24} md={12} lg={8} xl={8} className='mt-4'>
                                            <div style={{ backgroundColor: "#F5F8FA", padding: "10px", borderRadius: "10px" }}>
                                                <div className='d-flex flex-column align-items-end justify-content-center' style={{ padding: "20px", backgroundColor: "white", borderRadius: "10px" }}>
                                                    <p><span style={{ fontSize: "16px", fontWeight: 600, marginRight: "5px", color: "#222032" }}>Ad.</span>{getSite}</p>
                                                    <p style={{ color: "#3aa9ff" }}>{getTitr1} | {getTitr2} | {getTitr3}</p>
                                                    <p className='mb-1' style={{ color: "#7e829b" }}>{getDesc1} | {getDesc2}</p>
                                                </div>
                                            </div>
                                        </Col>)}
                                    </Row>

                                </div>
                            </Col>) : (null)}
                            {context.getService.category === "youtube" ? (<Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <div className='card-service mt-3'>
                                    <div className='d-flex flex-row align-items-center justify-content-start'>
                                        <div><img style={{ width: "40px", height: "40px" }} src={Config.adminPics + context.getService.photo} /></div>
                                        <div className='d-flex flex-column align-items-start justify-content-start' style={{ marginRight: "10px" }}>
                                            <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: 600 }}>{context.getService.name}</p>
                                            {params.get("corency") === "turkish_lir" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.turkish_lir_price} لیر</p>) : (null)}
                                            {params.get("corency") === "dollar" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.dollar_price} دلار</p>) : (null)}
                                            {params.get("corency") === "derham" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.derham_price} درهم</p>) : (null)}

                                        </div>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <p style={{ fontSize: "14px", fontWeight: 600, textAlign: "right" }}><img style={{ width: "5px", height: "5px" }} src={window.origin + "/User/doti.svg"} /> {context.getService.name}</p>
                                    <Row gutter={16} className='mt-4'>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>لینک کانال یوتیوب خود را وارد کنید <span className='text-danger'>*</span></p>
                                            <input onChange={(e) => {
                                                setSite(e.target.value)
                                            }} value={getSite} className='into' />
                                        </Col>

                                    </Row>

                                </div>
                            </Col>) : (null)}
                            {context.getService.category === "appstore" ? (<Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <div className='card-service mt-3'>
                                    <div className='d-flex flex-row align-items-center justify-content-start'>
                                        <div><img style={{ width: "40px", height: "40px" }} src={Config.adminPics + context.getService.photo} /></div>
                                        <div className='d-flex flex-column align-items-start justify-content-start' style={{ marginRight: "10px" }}>
                                            <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: 600 }}>{context.getService.name}</p>
                                            {params.get("corency") === "turkish_lir" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.turkish_lir_price} لیر</p>) : (null)}
                                            {params.get("corency") === "dollar" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.dollar_price} دلار</p>) : (null)}
                                            {params.get("corency") === "derham" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.derham_price} درهم</p>) : (null)}

                                        </div>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <p style={{ fontSize: "14px", fontWeight: 600, textAlign: "right" }}><img style={{ width: "5px", height: "5px" }} src={window.origin + "/User/doti.svg"} /> {context.getService.name}</p>
                                    <Row gutter={16} className='mt-4'>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='d-flex flex-column'>
                                            <p className='mb-1' style={{ textAlign: "right", fontSize: "13px", fontWeight: 600 }}>لینک اپلیکیشن خود را وارد کنید <span className='text-danger'>*</span></p>
                                            <input onChange={(e) => {
                                                setSite(e.target.value)
                                            }} value={getSite} className='into' />
                                        </Col>
                                    </Row>

                                </div>
                            </Col>) : (null)}
                            {(context.getService.category !== "appstore" && context.getService.category !== "youtube" && context.getService.category !== "google") ? (<Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <div className='card-service mt-3'>
                                    <div className='d-flex flex-row align-items-center justify-content-start'>
                                        <div><img style={{ width: "40px", height: "40px" }} src={Config.adminPics + context.getService.photo} /></div>
                                        <div className='d-flex flex-column align-items-start justify-content-start' style={{ marginRight: "10px" }}>
                                            <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: 600 }}>{context.getService.name}</p>
                                            {params.get("corency") === "turkish_lir" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.turkish_lir_price} لیر</p>) : (null)}
                                            {params.get("corency") === "dollar" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.dollar_price} دلار</p>) : (null)}
                                            {params.get("corency") === "derham" ? (<p style={{ fontSize: "10px", fontWeight: 200, color: "#B5B5C3", marginBottom: "0px" }}>پلن {context.getService.derham_price} درهم</p>) : (null)}

                                        </div>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <p style={{ fontSize: "14px", fontWeight: 600, textAlign: "right" }}><img style={{ width: "5px", height: "5px" }} src={window.origin + "/User/doti.svg"} /> {context.getService.name}</p>


                                </div>
                            </Col>) : (null)}

                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <div className='card-service mt-3'>
                                    <p>نهایی کردن سفارش</p>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                    <div className='d-flex flex-column align-items-betwen justify-content-center'>
                                        {params.get("corency") === "turkish_lir" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>ارز</span>
                                            <span>لیر ترکیه</span>
                                        </div>) : (null)}
                                        {params.get("corency") === "dollar" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>ارز</span>
                                            <span>دلار</span>
                                        </div>) : (null)}
                                        {params.get("corency") === "derham" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>ارز</span>
                                            <span>درهم</span>
                                        </div>) : (null)}

                                        {params.get("corency") === "turkish_lir" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>مبلغ</span>
                                            <span>{context.getService.turkish_lir_price}</span>
                                        </div>) : (null)}
                                        {params.get("corency") === "dollar" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>مبلغ</span>
                                            <span>{context.getService.dollar_price}</span>
                                        </div>) : (null)}
                                        {params.get("corency") === "derham" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>مبلغ</span>
                                            <span>{context.getService.derham_price}</span>
                                        </div>) : (null)}


                                        {(context.getSettings !== null) ? (<>
                                            {params.get("corency") === "derham" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                                <span>قیمت ارز</span>
                                                <span>{`${context.getSettings.derham_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "turkish_lir" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                                <span>قیمت ارز</span>
                                                <span>{`${context.getSettings.turkish_lir_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "dollar" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                                <span>قیمت ارز</span>
                                                <span>{`${context.getSettings.dollar_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                        </>) : (null)}
                                        {(context.getSettings !== null && context.getService !== null) ? (<>
                                            {params.get("corency") === "derham" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                                <span>جمع کل</span>
                                                <span>{`${Math.ceil((context.getService.derham_price * context.getSettings.derham_price) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "turkish_lir" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                                <span>جمع کل</span>
                                                <span>{`${Math.ceil((context.getService.turkish_lir_price * context.getSettings.turkish_lir_price) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "dollar" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                                <span>جمع کل</span>
                                                <span>{`${Math.ceil((context.getService.dollar_price * context.getSettings.dollar_price) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                        </>) : (null)}

                                    </div>
                                    <hr style={{ borderTop: "2px dashed" }} />
                                        {(context.getSettings !== null && context.getService !== null) ? (<>
                                            {params.get("corency") === "derham" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>مالیات بر ارزش افزوده</span>
                                                <span>{`${(context.getService.tax / 100 * (context.getService.derham_price * context.getSettings.derham_price))}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "turkish_lir" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>مالیات بر ارزش افزوده</span>
                                                <span>{`${(context.getService.tax / 100 * (context.getService.turkish_lir_price * context.getSettings.turkish_lir_price))}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "dollar" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4'>
                                            <span>مالیات بر ارزش افزوده</span>
                                                <span>{`${(context.getService.tax / 100 * (context.getService.dollar_price * context.getSettings.dollar_price))}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                        </>) : (null)}
                                

                                    <div className='d-flex flex-column align-items-betwen justify-content-center '>
                                        {(context.getSettings !== null && context.getService !== null) ? (<>
                                            {params.get("corency") === "derham" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4 maliatBox'>
                                                <span>جمع کل</span>
                                                <span className='text-danger'>{`${Math.ceil((context.getService.derham_price * context.getSettings.derham_price + (context.getService.tax / 100 * (context.getService.derham_price * context.getSettings.derham_price))) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "turkish_lir" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4 maliatBox'>
                                                <span>جمع کل</span>
                                                <span className='text-danger'>{`${Math.ceil((context.getService.turkish_lir_price * context.getSettings.turkish_lir_price + (context.getService.tax / 100 * (context.getService.turkish_lir_price * context.getSettings.turkish_lir_price))) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                            {params.get("corency") === "dollar" ? (<div className='d-flex flex-row align-items-center justify-content-between mb-4 maliatBox'>
                                                <span>جمع کل</span>
                                                <span className='text-danger'>{`${Math.ceil((context.getService.dollar_price * context.getSettings.dollar_price + (context.getService.tax / 100 * (context.getService.dollar_price * context.getSettings.dollar_price))) / 1000) * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}تومان</span>
                                            </div>) : null}
                                        </>) : (null)}
                                    </div>
                                    <Button onClick={()=>{
                                        hanldeCheckoutBasket()
                                    }} className='btnM'>پرداخت و تکمیل سفارش</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>) : (null)}

            </Container>
        </Layout>
    );
};
export default Index;